import React, {
  useState,
  useRef,
  forwardRef,
  useEffect,
} from "react";
import {
  TextField,
} from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import _ from "lodash";
import { getFaviconUrl } from "../../../../healpers/utilityFunctions";

export default forwardRef(function Tables(props, ref) {
  const gridRef = useRef(null);
  const [searchValue, setSearchValue] = useState(null);
  const [mainTableData, setMainTableData] = useState([]);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const rowStyle = { background: "white" };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const renderImage = ({ value }) =>
    `<img style="height: 40px; width: 40px;" src="${getFaviconUrl(
      value
    )}">`;

  useEffect(() => {
    const list = searchValue?.split(" ");
    if (searchValue?.length && list?.length) {
      const filteredList = props.tableData.filter((data) =>
        list.includes(data.asin)
      );
      setMainTableData(filteredList);
    } else {
      setMainTableData(props.tableData);
    }
  }, [props.tableData, searchValue]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 24px",
        }}
      >
        <form
          className={props.classes.form}
          noValidate
          autoComplete="off"
          onSubmit={(e) => e.preventDefault()}
        >
          <TextField
            label="Search in the table"
            value={searchValue}
            onChange={handleChange}
          />
        </form>
      </div>
      <div
        className="ag-theme-material"
        style={{ height: 750, width: "100%" }}
        id="#grid-theme-wrapper"
      >
        <AgGridReact
          ref={gridRef}
          rowData={mainTableData}
          suppressExcelExport={true}
          rowSelection="multiple"
          tooltipShowDelay={100}
          tooltipMouseTrack={true}
          rowHeight={60}
          defaultColDef={{
            filter: "agTextColumnFilter",
            resizable: true,
            sortable: true,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          }}
          onGridReady={onGridReady}
          pagination={true}
          paginationPageSize={100}
          rowStyle={rowStyle}
          suppressDragLeaveHidesColumns={true}
          onSelectionChanged={() => {
            props?.setTableSelectedData(gridApi?.getSelectedRows() || []);
          }}
        >
          <AgGridColumn
            headerName=""
            width={50}
            headerCheckboxSelection={true}
            pinned="left"
            checkboxSelection={true}
          />
          <AgGridColumn headerName={''} field={'asin'} width={100} cellStyle={{
            "padding-top": "8px",
          }} cellRenderer={renderImage}/>
          {props.headerNames.length > 0 && (
            <>
              {props.headerNames.map(({ headerName, field }) => (
                <AgGridColumn
                  key={field}
                  headerName={headerName}
                  field={field}
                />
              ))}
            </>
          )}
        </AgGridReact>
      </div>
    </>
  );
});
