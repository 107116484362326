/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import * as XLSX from "xlsx";
import _ from "lodash";
import "date-fns";
import axios from "axios";
import moment from "moment";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

//icons
import PrintIcon from "@material-ui/icons/Print";
import CloudUpload from "@material-ui/icons/CloudUpload";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig, useUserState } from "../../context/UserContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

import useStyles from "./styles";
import { findMarketplace } from "../../healpers/utilityFunctions";

export default function CatalogVariations(props) {
  const exportFunctionRef = useRef();

  const classes = useStyles();
  const userState = useUserState();
  const vendorState = useVendorState();

  const [mainTableData, setMainTableData] = useState([]);
  const [variationsUploadTableData, setVariationsUploadTableData] = useState(
    []
  );
  const [mainTableDataSelectedRows, setMainTableDataSelectedRows] = useState(
    []
  );
  const [productTypes, setProductTypes] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState("");
  const [variationThemes, setVariationThemes] = useState([]);
  const [
    variationThemesDownloadInProgress,
    setVariationThemesDownloadInProgress,
  ] = useState(false);
  const [variationsUploadInProgress, setVariationsUploadInProgress] = useState(
    false
  );
  const [selectedVariationTheme, setSelectedVariationTheme] = useState("");
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [
    isLoadingVariationsUploadTableData,
    setIsLoadingVariationsUploadTableData,
  ] = useState(false);
  const [activeTable, setActiveTable] = useState("MAIN");

  const [headerNames, setHeaderNames] = useState([]);

  const loadingVariationsUploadTableData = async () => {
    try {
      setIsLoadingVariationsUploadTableData(true);
      const catalogVariations = await axios.get(
        BASE_URL + api.fetchCatalogVariationsUpload
      );
      setIsLoadingVariationsUploadTableData(false);
      if (catalogVariations.data?.status === "Success") {
        setVariationsUploadTableData(catalogVariations.data.data);
      } else {
        window.alert(
          catalogVariations.data?.message || "Failed to load data. Try again."
        );
        setVariationsUploadTableData([]);
      }
      setIsLoadingVariationsUploadTableData(false);
    } catch (e) {
      console.log(e);
      window.alert("Failed to load data. Try again.");
      setIsLoadingVariationsUploadTableData(false);
      setVariationsUploadTableData([]);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setFullPageLoader(true);
        const response = await axios.get(
          BASE_URL + api.catalogGetAPI,
          tokenConfig({
            vendorCode: vendorState.selected,
          })
        );

        if (response?.data?.data) {
          const data = response.data.data;
          const headerNames = [
            {
              headerName: "ASIN",
              field: "asin",
            },
            {
              headerName: "SKU",
              field: "sku",
            },
            {
              headerName: "Title",
              field: "itemName",
            },
            {
              headerName: "Parent ASIN",
              field: "parentASIN",
            },
            {
              headerName: "Product Type",
              field: "productType",
            },
          ];
          setHeaderNames(headerNames);
          setMainTableData(data);
          setProductTypes(
            _.uniq(
              data
                .filter(
                  (item) =>
                    item.productType && item.productType !== "YET_TO_BE_FILLED"
                )
                .map((item) => item.productType)
            ).sort()
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setFullPageLoader(false);
      }
    }

    if (vendorState.selected) {
      fetchData();
    }

    return () => {
      setMainTableData([]);
      setMainTableDataSelectedRows([]);
      setSelectedVariationTheme("");
      setSelectedProductType("");
      setVariationThemes([]);
      setProductTypes([]);
    };
  }, [vendorState.selected]);

  useEffect(() => {
    async function fetchProductTypeVariationThemes() {
      setVariationThemes([]);
      setSelectedVariationTheme("");
      try {
        const response = await axios.get(
          BASE_URL + api.productTypeVariationThemes,
          {
            params: {
              productType: selectedProductType,
              marketplaceId: findMarketplace(vendorState?.selected),
            },
          }
        );

        if (response?.data?.data) {
          const data = response.data.data;
          setVariationThemes(data?.sort());
          setSelectedVariationTheme(data?.sort()?.[0]);
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (selectedProductType) {
      fetchProductTypeVariationThemes();
    }
  }, [selectedProductType]);

  useEffect(() => {
    if (activeTable !== "MAIN") {
      loadingVariationsUploadTableData();
    }
  }, [activeTable]);

  const DetailedStatusRenderer = (props) => {
    return (
      <>
        {Object.entries(props.data.detailedStatus)
          .map((item) => `${item[0]}:${item[1]}`)
          .sort()
          .reverse()
          .join(", ")}
      </>
    );
  };

  const ActionButtonsRenderer = (props) => {
    return (
      <div
        style={{
          display: "flex",
          gap: "8px",
          paddingTop: "8px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={["PENDING", "IN_PROGRESS"].includes(props.data.status)}
          onClick={async () => {
            try {
              const retry = await axios({
                method: "post",
                url: BASE_URL + api.retryCatalogVariations,
                headers: {
                  ...tokenConfig().headers,
                },
                data: {
                  id: props.data.id,
                },
              });
              if (retry.data?.status === "Success") {
                window.alert(retry.data?.message);
                await loadingVariationsUploadTableData();
              } else {
                window.alert(
                  retry.data?.message || "Failed to initiate retry."
                );
              }
            } catch (e) {
              console.log(e);
              window.alert("Failed to initiate retry.");
            }
          }}
        >
          Retry
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={props.data.status !== "COMPLETED"}
          onClick={() => {
            const downloadURL = `${BASE_URL}${api.downloadCatalogVariations}/Result-${props.data.name}`;
            window.open(downloadURL, "_blank", "noopener,noreferrer");
          }}
        >
          Download
        </Button>
      </div>
    );
  };

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Catalog Variations"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Catalog
              </Link>
              <Typography className={classes.link}>Variations</Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        size="small"
                        style={{
                          width: "400px",
                        }}
                      >
                        <InputLabel>Select Product Type</InputLabel>
                        <Select
                          value={selectedProductType}
                          onChange={(e) => {
                            setSelectedProductType(e.target.value);
                          }}
                          label="Select Product Type"
                          disabled={productTypes.length === 0}
                        >
                          {productTypes.length > 0 ? (
                            productTypes.map((productType, index) => {
                              return (
                                <MenuItem value={productType} key={index}>
                                  {productType}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem value={"NO_DATA"} key={0}>
                              <i>NO_DATA</i>
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        size="small"
                        style={{
                          width: "400px",
                        }}
                      >
                        <InputLabel>Select Variation Theme</InputLabel>
                        <Select
                          value={selectedVariationTheme}
                          onChange={(e) => {
                            setSelectedVariationTheme(e.target.value);
                          }}
                          label="Select Variation Theme"
                          disabled={variationThemes.length === 0}
                        >
                          {variationThemes.length > 0 ? (
                            variationThemes.map((variationTheme, index) => {
                              return (
                                <MenuItem
                                  value={variationTheme}
                                  key={index}
                                  style={{
                                    width: "400px",
                                    whiteSpace: "normal",
                                    borderBottom: "0.5px solid gray",
                                  }}
                                >
                                  {variationTheme}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem value={"NO_DATA"} key={0}>
                              <i>NO_DATA</i>
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                        alignItems: "end",
                        gap: "16px",
                      }}
                    >
                      <label htmlFor="upload-variations">
                        <input
                          style={{ display: "none" }}
                          id="upload-variations"
                          name="upload-variations"
                          type="file"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={async (e) => {
                            const file = e.target.files[0];
                            const reader = new FileReader();
                            reader.onload = async (event) => {
                              try {
                                setVariationsUploadInProgress(true);
                                const workbook = XLSX.read(
                                  event.target.result,
                                  {
                                    type: "array",
                                  }
                                );
                                if (!workbook.SheetNames.includes("PARENT")) {
                                  window.alert(
                                    `Missing PARENT sheet. Check the file and Try again.`
                                  );
                                } else if (
                                  !workbook.SheetNames.includes("CHILD")
                                ) {
                                  window.alert(
                                    `Missing CHILD sheet. Check the file and Try again.`
                                  );
                                } else {
                                  const parentSheetData = XLSX.utils.sheet_to_json(
                                    workbook.Sheets["PARENT"],
                                    {
                                      defval: "",
                                    }
                                  );
                                  const childSheetData = XLSX.utils.sheet_to_json(
                                    workbook.Sheets["CHILD"],
                                    {
                                      defval: "",
                                    }
                                  );
                                  if (
                                    parentSheetData.filter(
                                      (item) => item.parent_sku
                                    ).length === 0 &&
                                    childSheetData.filter(
                                      (item) => item.child_sku
                                    ).length === 0
                                  ) {
                                    window.alert("File is empty. Try again.");
                                  } else {
                                    let isFileValid = true;
                                    for (
                                      let index = 0;
                                      index < parentSheetData.length;
                                      index++
                                    ) {
                                      if (
                                        parentSheetData[index]["parent_sku"]
                                      ) {
                                        for (const item of Object.entries(
                                          parentSheetData[index]
                                        )) {
                                          if (
                                            !item[0].endsWith("language_tag") &&
                                            !item[0].endsWith(
                                              "marketplace_id"
                                            ) &&
                                            !item[1]
                                          ) {
                                            isFileValid = false;
                                            window.alert(
                                              `PARENT sheet. Missing required field on row ${
                                                index + 1
                                              }. Check the file and Try again.`
                                            );
                                            break;
                                          }
                                        }
                                      }
                                      if (!isFileValid) {
                                        break;
                                      }
                                    }
                                    if (isFileValid) {
                                      for (
                                        let index = 0;
                                        index < childSheetData.length;
                                        index++
                                      ) {
                                        if (
                                          childSheetData[index]["child_sku"]
                                        ) {
                                          for (const item of Object.entries(
                                            childSheetData[index]
                                          )) {
                                            if (
                                              !item[0].endsWith(
                                                "language_tag"
                                              ) &&
                                              !item[0].endsWith(
                                                "marketplace_id"
                                              ) &&
                                              !item[1]
                                            ) {
                                              isFileValid = false;
                                              window.alert(
                                                `CHILD sheet. Missing required field on row ${
                                                  index + 1
                                                }. Check the file and Try again.`
                                              );
                                              break;
                                            }
                                          }
                                        }
                                        if (!isFileValid) {
                                          break;
                                        }
                                      }
                                      if (isFileValid) {
                                        const formData = new FormData();
                                        formData.append("file", file);
                                        formData.append("filename", file.name);
                                        formData.append(
                                          "vendorCode",
                                          vendorState?.selected
                                        );
                                        formData.append(
                                          "user",
                                          userState.userData.email
                                        );
                                        const upload = await axios({
                                          method: "post",
                                          url:
                                            BASE_URL +
                                            api.catalogVariationsUpload,
                                          headers: {
                                            ...tokenConfig().headers,
                                            "Access-Control-Allow-Origin": "*",
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                          data: formData,
                                        });
                                        if (upload.data?.status === "Success") {
                                          window.alert(
                                            "Upload success. You will be notified once processing completed."
                                          );
                                        } else {
                                          window.alert(
                                            "Upload failed. Try again."
                                          );
                                        }
                                      }
                                    }
                                  }
                                }
                              } catch (err) {
                                console.error(err);
                                window.alert(
                                  err?.message || "File validation failed."
                                );
                              } finally {
                                setVariationsUploadInProgress(false);
                              }
                            };
                            reader.readAsArrayBuffer(e.target.files[0]);
                          }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          component="span"
                          startIcon={<CloudUpload />}
                          disabled={variationsUploadInProgress}
                        >
                          Variation Upload
                          <CircularProgress
                            color="inherit"
                            style={{
                              display: variationsUploadInProgress
                                ? "block"
                                : "none",
                              width: "16px",
                              height: "16px",
                              marginLeft: "4px",
                            }}
                          />
                        </Button>
                      </label>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<PrintIcon />}
                        disabled={
                          !selectedProductType ||
                          !selectedVariationTheme ||
                          variationThemesDownloadInProgress
                        }
                        onClick={async () => {
                          try {
                            setVariationThemesDownloadInProgress(true);
                            const response = await axios.get(
                              BASE_URL + api.productTypeVariationThemes,
                              {
                                params: {
                                  productType: selectedProductType,
                                  marketplaceId: findMarketplace(
                                    vendorState?.selected
                                  ),
                                  variationTheme: selectedVariationTheme,
                                  vendorCode: vendorState?.selected,
                                  skus:
                                    mainTableDataSelectedRows.length > 0
                                      ? mainTableDataSelectedRows
                                          .map((item) => item.sku)
                                          .join(",")
                                      : undefined,
                                },
                              }
                            );

                            if (response?.data?.data) {
                              const parentAttributes = [];
                              parentAttributes[0] = {
                                product_type: selectedProductType,
                                variation_theme: selectedVariationTheme,
                                parent_sku: "",
                              };
                              const parentHideColumns = [];
                              Object.entries(response.data.data.parent).forEach(
                                (item, index) => {
                                  if (
                                    item[0].endsWith("language_tag") ||
                                    item[0].endsWith("marketplace_id")
                                  ) {
                                    parentHideColumns.push(3 + index);
                                  }
                                  parentAttributes[0] = {
                                    ...parentAttributes[0],
                                    [item[0]]: "",
                                  };
                                }
                              );
                              const worksheetParent = XLSX.utils.json_to_sheet(
                                parentAttributes
                              );
                              worksheetParent["!cols"] = [];
                              parentHideColumns.forEach((column) => {
                                worksheetParent["!cols"][column] = {
                                  hidden: true,
                                };
                              });
                              Object.keys(parentAttributes[0]).forEach(
                                (item, index) => {
                                  worksheetParent["!cols"][index] = {
                                    ...worksheetParent["!cols"][index],
                                    wch: item.length,
                                  };
                                }
                              );

                              const childHideColumns = [];
                              const childAttributes = [];
                              childAttributes[0] = {
                                product_type: selectedProductType,
                                variation_theme: selectedVariationTheme,
                                parent_sku: "",
                                child_sku: "",
                              };
                              Object.entries(response.data.data.child).forEach(
                                (item, index) => {
                                  if (
                                    item[0].endsWith("language_tag") ||
                                    item[0].endsWith("marketplace_id")
                                  ) {
                                    childHideColumns.push(4 + index);
                                  }
                                  childAttributes[0] = {
                                    ...childAttributes[0],
                                    [item[0]]: "",
                                  };
                                }
                              );
                              const worksheetChild = XLSX.utils.json_to_sheet([
                                ...(response.data.data.childData?.length > 0
                                  ? []
                                  : childAttributes),
                                ...(response.data.data.childData || []),
                              ]);
                              worksheetChild["!cols"] = [];
                              childHideColumns.forEach((column) => {
                                worksheetChild["!cols"][column] = {
                                  hidden: true,
                                };
                              });
                              Object.keys(childAttributes[0]).forEach(
                                (item, index) => {
                                  worksheetChild["!cols"][index] = {
                                    ...worksheetChild["!cols"][index],
                                    wch: item.length,
                                  };
                                }
                              );

                              const workbook = XLSX.utils.book_new();
                              XLSX.utils.book_append_sheet(
                                workbook,
                                worksheetParent,
                                "PARENT"
                              );
                              XLSX.utils.book_append_sheet(
                                workbook,
                                worksheetChild,
                                "CHILD"
                              );
                              XLSX.writeFile(
                                workbook,
                                `${
                                  vendorState?.selected
                                }-${selectedProductType}-${selectedVariationTheme.replaceAll(
                                  "/",
                                  "-"
                                )}.xlsx`
                              );
                            }
                          } catch (error) {
                            console.error(error);
                          } finally {
                            setVariationThemesDownloadInProgress(false);
                          }
                        }}
                      >
                        Variation Download
                        <CircularProgress
                          color="inherit"
                          style={{
                            display: variationThemesDownloadInProgress
                              ? "block"
                              : "none",
                            width: "16px",
                            height: "16px",
                            marginLeft: "4px",
                          }}
                        />
                      </Button>
                      <Button
                        variant="text"
                        color="primary"
                        style={{
                          padding: 0,
                        }}
                        onClick={() => {
                          setActiveTable(
                            activeTable === "MAIN" ? "UPLOADS" : "MAIN"
                          );
                        }}
                      >
                        {`View ${activeTable === "MAIN" ? "Uploads" : "ASINs"}`}
                      </Button>
                    </div>
                  </div>
                </>
              }
            >
              {activeTable === "MAIN" ? (
                <Table
                  ref={exportFunctionRef}
                  tableData={mainTableData}
                  setTableSelectedData={(tableSelectedData) =>
                    setMainTableDataSelectedRows(tableSelectedData)
                  }
                  headerNames={headerNames}
                  classes={classes}
                />
              ) : isLoadingVariationsUploadTableData ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <div
                  className="ag-theme-material"
                  style={{ height: 550, width: "100%" }}
                  id="#grid-theme-wrapper"
                >
                  <AgGridReact
                    rowData={variationsUploadTableData}
                    suppressExcelExport={true}
                    defaultColDef={{
                      filter: "agTextColumnFilter",
                      resizable: true,
                      sortable: true,
                      headerComponentParams: {
                        template:
                          '<div class="ag-cell-label-container" role="presentation">' +
                          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                          "  </div>" +
                          "</div>",
                      },
                    }}
                    frameworkComponents={{
                      actionButtonsRenderer: ActionButtonsRenderer,
                      detailedStatusRenderer: DetailedStatusRenderer,
                    }}
                    pagination={true}
                    paginationPageSize={10}
                    suppressDragLeaveHidesColumns={true}
                  >
                    <AgGridColumn headerName="File" field="name" width={300} />
                    <AgGridColumn
                      headerName="Status"
                      field="status"
                      width={140}
                    />
                    <AgGridColumn
                      cellRenderer="detailedStatusRenderer"
                      headerName="Detailed Status"
                      field="detailedStatus"
                      width={350}
                    />
                    <AgGridColumn
                      headerName="Uploaded On"
                      field="createdAt"
                      cellRenderer={({ value }) =>
                        moment(value).format("MM-DD-YYYY")
                      }
                      width={160}
                    />
                    <AgGridColumn
                      headerName="User"
                      field="uploader"
                      width={150}
                    />
                    <AgGridColumn
                      cellRenderer="actionButtonsRenderer"
                      filter={false}
                      sortable={false}
                      width={220}
                    />
                  </AgGridReact>
                </div>
              )}
            </Widget>
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={fullPageLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
