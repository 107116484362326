import React, {
  useState,
  useRef,
  forwardRef,
  useMemo,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  Typography,
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Backdrop,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { useHistory } from "react-router-dom";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import { useVendorState } from "../../../../context/VendorContext";
import axios from "axios";
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";
import { tokenConfig } from "../../../../context/UserContext";
import { Validator } from "jsonschema";
import _ from "lodash";
import * as XLSX from "xlsx";
import * as jsonRefs from "json-refs";
import { getFaviconUrl } from "../../../../healpers/utilityFunctions";

const CustomErrorsMessages = (props) => {
  return (
    <Box
      style={{
        color: "#4A4A4A",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "#fff",
        padding: "16px",
        marginBottom: "16px",
      }}
    >
      <Typography variant="h6">Errors</Typography>
      <List>
        {props.errors.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <ErrorIcon style={{ color: "red" }} />
            </ListItemIcon>
            <ListItemText>
              <Typography style={{ color: "red" }} variant="body2">
                {item}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

// Because we operating in one markeplace and with one langauge tag for now
// TODO: Remove hardcoded language_tag value en_US from the frontend atleast
const DELETE_KEYS = ["marketplace_id", "language_tag"];

const SKIP_FIELDS = {
  // Very deeply nested fields which cause issue when simplifying and rendering
  // purchasable_offer: true,
  cable: true
};

function parseItem(type, data) {
  switch (type) {
    case "string":
      return data.toString();
    case "number":
    case "integer":
      return isNaN(Number(data)) ? undefined : Number(data);
    case "boolean":
      if (!isNaN(Number(data))) {
        return Boolean(Number(data));
      } else {
        return "true" == data;
      }
    default:
      console.log(type);
      throw new Error("type not matched");
  }
}

// Simplifies the schema
function processItem(param) {
  const item = _.cloneDeep(param);
  const { type } = item;
  delete item.examples;

  if (["string", "number", "integer", "boolean", "null"].indexOf(type) >= 0) {
    if (item?.anyOf) {
      for (const value of item.anyOf) {
        if (value?.enum) {
          value.title = "Select from options";
        } else {
          value.title = "Enter custom value";
        }
      }
    }
    return item;
  }

  if (type == "object") {
    let { properties, required } = item;
    required = required.filter((key) => {
      if (DELETE_KEYS.indexOf(key) >= 0) {
        delete properties[key];
        return false;
      }
      return true;
    });
    if (Object.keys(properties).length == 0) return null;

    for (const key of Object.keys(properties)) {
      properties[key] = processItem(properties[key]);
    }
    // if (Object.keys(properties).length == 1) {
    //   const key = Object.keys(properties)[0];
    //   return properties[key];
    // }

    item.properties = properties;
    item.required = required;
    return item;
  }

  if (type == "array") {
    let { selectors, maxItems, maxUniqueItems = 0 } = item;

    if (maxItems && maxItems == 1) {
      return processItem(item.items);
    }

    if (selectors) {
      selectors = selectors.filter((key) => DELETE_KEYS.indexOf(key) < 0);
      if (
        selectors.length == 0 ||
        (selectors.length == 1 && maxUniqueItems <= 1)
      ) {
        const { title, description } = item;
        item.items.title = title;
        item.items.description = description;
        return processItem(item.items);
      } else if (selectors.length >= 1 && maxUniqueItems >= 1) {
        item.maxItems = maxUniqueItems;
      }
    }
    item.items = processItem(item.items);
    return item;
  }

  throw new Error("Not a recognized type");
}

function markNotRequired(schema) {
  const item = _.cloneDeep(schema);
  const { type } = item;

  // Delete required section
  delete item.required;
  if (["string", "number", "integer", "boolean", "null"].indexOf(type) >= 0) {
    return item;
  } else if (type == "object") {
    const { properties } = item;
    for (const key of Object.keys(properties)) {
      properties[key] = markNotRequired(properties[key]);
    }
    item.properties = properties;
    return item;
  } else if (type == "array") {
    delete item.minItems;
    item.items = markNotRequired(item.items);
    return item;
  }
}

function createSchema(productSchema, attributes) {
  const schema = {
    type: "object",
    properties: {},
    required: ["required", "optional", "extra"],
  };

  schema.properties["required"] = productSchema.required.reduce(
    (obj, key) => {
      if (SKIP_FIELDS[key]) return obj;
      obj.properties[key] = processItem(productSchema["properties"][key]);
      return obj;
    },
    {
      type: "object",
      properties: {},
      required: productSchema.required,
      title: "Required",
      description:
        "All these fields are required and must not be empty for this listing.",
    }
  );

  schema.properties["optional"] = Object.keys(attributes).reduce(
    (obj, key) => {
      if (SKIP_FIELDS[key]) return obj;

      // Skip if already covered in required fiels
      if (schema.properties.required.properties[key]) return obj;
      obj.properties[key] = processItem(productSchema["properties"][key]);
      return obj;
    },
    {
      type: "object",
      properties: {},
      title: "Optional",
      description:
        "All these fields are optional but already exist in the current listing.",
    }
  );

  schema.properties["extra"] = Object.keys(productSchema.properties).reduce(
    (obj, key) => {
      if (SKIP_FIELDS[key]) return obj;
      if (
        schema.properties.required.properties[key] ||
        schema.properties.optional.properties[key]
      )
        return obj;
      let itemSchema = processItem(productSchema["properties"][key]);
      itemSchema = markNotRequired(itemSchema);
      obj.properties[key] = itemSchema;
      return obj;
    },
    {
      type: "object",
      properties: {},
      title: "Extra",
      description:
        "All these fields are optional but don't yet exist in the current listing.",
    }
  );

  return schema;
}

// Simplifies the payload
function processPayloadItem(item, matchSchema) {
  const PRIMITIVE_TYPES = ["string", "number", "boolean", "null"];

  // handle the primitive types
  if (
    ["string", "number", "integer", "boolean", "null"].indexOf(
      matchSchema.type
    ) >= 0
  ) {
    let value = null;
    while (true) {
      if (typeof item == "object") {
        if (item === null) {
          item = "";
        } else {
          let keys = Object.keys(item);
          keys = keys.filter((x) => DELETE_KEYS.indexOf(x) < 0);
          if (keys.length != 1) {
            console.error(
              "SOLVE THIS: keys length should not be greater than 0",
              keys,
              matchSchema
            );
          }
          // item = parseItem(matchSchema.type, item[keys[0]]);
          item = item[keys[0]];
        }
      } else if (PRIMITIVE_TYPES.indexOf(typeof item) >= 0) {
        value = parseItem(matchSchema.type, item);
        break;
      } else {
        throw new Error("unknown type seen");
      }
    }
    if (matchSchema?.editable === false && value != "") {
      matchSchema["readOnly"] = true;
      delete matchSchema["anyOf"];
      delete matchSchema["oneOf"];
      delete matchSchema["enum"];
      delete matchSchema["enumNames"];
    }
    return [value, matchSchema];
  }

  if (matchSchema.type == "array") {
    const values = [];

    let items = _.cloneDeep(item);
    if (!Array.isArray(items)) {
      let keys = Object.keys(items);
      keys = keys.filter((x) => DELETE_KEYS.indexOf(x) < 0);
      if (keys.length != 1)
        throw new Error("Keys cannot be of length anything other than 1");
      items = [item[keys[0]]];
    }
    for (const value of items) {
      const [obj, schema] = processPayloadItem(value, matchSchema.items);
      matchSchema.items = schema;
      values.push(obj);
    }
    return [values, matchSchema];
  }
  if (matchSchema.type == "object") {
    const value = {};
    let items = _.cloneDeep(item);
    if (Array.isArray(items)) {
      if (items.length != 1 && matchSchema.title != "External Product ID") {
        console.error(
          "SOLVE THIS: keys length should not be greater than 0",
          items,
          matchSchema
        );
      }
      items = items[0];
    }
    for (const key of Object.keys(items)) {
      if (DELETE_KEYS.indexOf(key) >= 0) continue;
      const [obj, schema] = processPayloadItem(
        items[key],
        matchSchema.properties[key]
      );
      matchSchema.properties[key] = schema;
      value[key] = obj;
    }
    return [value, matchSchema];
  }

  throw new Error("Type did not match anything");
}

function flatten_schema(key, schema) {
  const headers = [];
  const type = schema.type;
  if (["string", "number", "integer", "boolean"].indexOf(type) >= 0) {
    let values = "";
    if (schema.enum) {
      values = schema.enum.join(", ");
    } else if (schema?.anyOf || schema?.oneOf) {
      let enumValues = schema?.anyOf ? schema.anyOf : schema?.oneOf;
      enumValues = enumValues.filter((x) => x.enum).map((x) => x.enum)[0];
      values = enumValues ? enumValues.join(", ") : "";
    }
    headers.push({
      key,
      values,
    });
  } else if (type == "object") {
    for (const property of Object.keys(schema.properties)) {
      const propertyKey = flatten_schema(property, schema.properties[property]);
      headers.push(
        ...propertyKey.map((x) => ({
          key: key + "$" + x.key,
          values: x.values,
        }))
      );
    }
  } else if (type == "array") {
    const itemskey = flatten_schema("", schema.items);
    const { maxUniqueItems, maxItems } = schema;
    let itemsCount = maxItems ? maxItems : maxUniqueItems ? maxUniqueItems : 1;
    itemsCount = itemsCount > 5 ? 5 : itemsCount;
    for (const i in Array.from({ length: itemsCount })) {
      headers.push(
        ...itemskey.map((x) => ({
          key: key + "[]" + i + x.key,
          values: x.values,
        }))
      );
    }
  } else {
    console.log(key, schema);
    throw new Error("not a recognized type");
  }
  return headers;
}

function flatten_payload(key, payload) {
  const obj = {};
  if (["string", "number", "boolean"].indexOf(typeof payload) >= 0) {
    obj[key] = payload;
  } else if (Array.isArray(payload)) {
    for (const i in payload) {
      for (const [itemKey, value] of Object.entries(
        flatten_payload("", payload[i])
      )) {
        const objKey = key + "[]" + i + itemKey;
        obj[objKey] = value;
      }
    }
  } else if (typeof payload == "object") {
    for (const property of Object.keys(payload)) {
      for (const [itemKey, value] of Object.entries(
        flatten_payload(property, payload[property])
      )) {
        const objKey = key + "$" + itemKey;
        obj[objKey] = value;
      }
    }
  } else {
    console.log(key, payload);
    throw new Error("not a recognized type");
  }
  return obj;
}

function validatePayload(data, schema, metaSchema) {
  const payload = _.cloneDeep(data);

  const v = new Validator();
  v.addSchema(metaSchema, metaSchema["$id"]);
  const obj = v.validate(payload, schema);

  return obj;
}

function customSchemaChanges(schema) {
  // Bullet_points is an array with a max 5 items
  if (schema.properties.bullet_point) {
    schema.properties.bullet_point = {
      ...schema.properties.bullet_point,
      selectors: ["marketplace_id", "language_tag", "value"],
      maxUniqueItems: 5,
      maxItems: 5,
    };
  }

  // merchant_suggested_asin is a required field
  schema.required = [...schema.required, "merchant_suggested_asin"];
  return schema;
}

export default forwardRef(function Tables(props, ref) {
  const gridRef = useRef(null);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  var vendorState = useVendorState();
  const [searchValue, setSearchValue] = useState(null);
  const [mainTableData, setMainTableData] = useState([]);

  const [gridApi, setGridApi] = useState(null);

  const [showUpdateContentLink, setShowUpdateContentLink] = useState(false);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  function customPayloadChanges(payload, asin, marketplaceId) {
    // Auto populate merchant_suggested_asin
    if (payload.attributes.merchant_suggested_asin) {
      const merchant_suggested_asin =
        payload.attributes.merchant_suggested_asin;
      const messages = [];
      if (merchant_suggested_asin.length > 1) {
        messages.push("More than one asin in merchant_suggested_asin");
      }
      if (merchant_suggested_asin[0].value != asin) {
        messages.push("Requested asin and payload asin not matching");
      }
      setErrorMessage(messages);
    } else {
      payload.attributes.merchant_suggested_asin = [
        {
          value: asin,
          marketplace_id: marketplaceId,
        },
      ];
    }

    return payload;
  }

  useImperativeHandle(ref, () => ({
    async onBtnExport() {
      setErrorMessage([]);
      setfullPageLoader(true);
      if (gridApi.getSelectedRows().length === 0) {
        setErrorMessage(["Please select atleast 1 row for exporting"]);
        setfullPageLoader(false);
        return;
      }

      const vendorCode = vendorState.selected;

      if (!vendorCode) {
        setErrorMessage(["No vendor selected"]);
        setfullPageLoader(false);
        return;
      }

      const errors = [];
      const products = {};

      for (const {
        asin,
        sku,
        marketplaceId,
        productType,
        vcVendorCode
      } of gridApi.getSelectedRows()) {
        const productInfo = `${asin} ${sku} ${marketplaceId} `;
        try {
          if (!asin || !sku || !marketplaceId || !productType) {
            throw new Error(productInfo + "data missing");
          }
          const response = await axios.get(
            BASE_URL + api.spListing + vendorCode,
            tokenConfig({
              asin,
              sku,
              marketplaceId,
              vcVendorCode,
            })
          );
          const productSchema = response.data.data.productSchema;
          const originalSchema = customSchemaChanges(productSchema.schema);
          let refResolvedOriginalSchema = await jsonRefs.resolveRefs(originalSchema);
          refResolvedOriginalSchema = refResolvedOriginalSchema.resolved;

          const payload = customPayloadChanges(
            response.data.data.payload,
            asin,
            marketplaceId
          );

          const attributes = payload.attributes;

          // Convert data and store the data in array
          const schema = createSchema(refResolvedOriginalSchema, attributes);

          const formData = Object.keys(attributes).reduce(
            (obj, key) => {
              if (SKIP_FIELDS[key]) return obj;

              const isRequired = refResolvedOriginalSchema.required.indexOf(key) >= 0;

              const rootKey = isRequired ? "required" : "optional";
              const itemSchema = schema.properties[rootKey].properties[key];

              const [convertedItem, updatedSchema] = processPayloadItem(
                attributes[key],
                itemSchema
              );
              obj[rootKey][key] = convertedItem;
              schema.properties[rootKey].properties[key] = updatedSchema;

              return obj;
            },
            {
              required: {},
              optional: {},
            }
          );

          const obj = {
            asin,
            sku,
            marketplaceId,
            productType,
            vcVendorCode,
            ...formData,
          };
          const flat_data = flatten_payload("root", obj);

          if (products[productType]) {
            products[productType].data.push(flat_data);
          } else {
            const flatSchema = flatten_schema("root", schema);
            const schemaHeaders = flatSchema.map((x) => x.key);
            products[productType] = {
              headers: [
                ...["asin", "sku", "marketplaceId", "productType", "vcVendorCode"].map(
                  (x) => "root$" + x
                ),
                ...schemaHeaders,
              ],
              schema: flatSchema,
              data: [flat_data],
            };
          }
        } catch (err) {
          console.log(err);
          const { response } = err;
          if (response && response.data && response.data.message) {
            const { message } = response.data;
            errors.push(productInfo + message);
          } else {
            errors.push(productInfo + "Error occurred while loading data");
          }
        }
      }

      for (const [key, value] of Object.entries(products)) {
        const { headers, data, schema } = value;
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data, { header: headers });

        const worksheetValues = XLSX.utils.json_to_sheet(
          schema.filter((s) => {
            if(s.values.length > 32767) {
              return false;
            } else {
              return s.values;
            }
          })
        );

        XLSX.utils.book_append_sheet(workbook, worksheet, key);
        XLSX.utils.book_append_sheet(workbook, worksheetValues, "VALUES");

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const excelBlob = new Blob([excelBuffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        var worksheetFileDownloadEle = document.createElement("a");
        worksheetFileDownloadEle.download = key + ".xlsx";
        worksheetFileDownloadEle.href = window.URL.createObjectURL(excelBlob);
        worksheetFileDownloadEle.click();

        setTimeout(() => {
          console.log("300 mil passeod");
        }, 300);
      }

      setErrorMessage(errors);
      setfullPageLoader(false);
    },
  }));

  const rowStyle = { background: "white" };

  const ButtonRenderer = (props) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        disabled={!props.data.supported || !props.data.sdProgram}
        style={{ textTransform: "capitalize", width: 100 }}
        onClick={() => {
          const { asin, sku, marketplaceId } = props.data;
          history.push("/app/catalog/updateItem", { sku, asin, marketplaceId });
        }}
      >
        Edit
      </Button>
    );
  };
  const VersionLoad = (props) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        disabled={!props.data.supported || !props.data.sdProgram}
        style={{ textTransform: "capitalize", width: 100 }}
        onClick={() => {
          const { asin, sku, marketplaceId } = props.data;
          history.push("/app/catalog/versions", { sku, asin, marketplaceId });
        }}
      >
        Versions
      </Button>
    );
  };
  const isRowSelectable = useMemo(() => {
    return (params) => {
      return !!params.data && (params.data.supported && params.data.sdProgram);
    };
  }, []);
  const getRowStyle = useMemo(() => {
    return (params) => {
      if (!params.data.supported || !params.data.sdProgram) {
        return {
          color: "rgba(0, 0, 0, 0.45)",
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        };
      }
    };
  }, []);
  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  function isUrlValid(url) {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  }

  const ImageCellRenderer = ({ value }) => {
    return (
      <div>
        {isUrlValid(value) ? (
          <img
            src={value}
            alt="Image"
            style={{
              height: "60px",
              width: "60px",
              display: "block",
              padding: "10px",
            }}
          />
        ) : (
          <div> {value} </div>
        )}
      </div>
    );
  };

  const CustomToolTip = ({ value }) => {
    return (
      <div
        style={{
          position: "absolute",
          top: "18%",
          height: "50%",
          width: "50%",
          left: "200px",
        }}
      >
        {isUrlValid(value) ? (
          <img
            src={value}
            alt="Image"
            style={{
              height: "100%",
            }}
          />
        ) : (
          <div> {value} </div>
        )}
      </div>
    );
  };

  const renderImage = ({ value }) => `<img style="height: 60px; width: 60px; padding: 10px" src="${getFaviconUrl(value)}">`;

  useEffect(() => {
    const list = searchValue?.split(" ");
    if (searchValue?.length && list?.length) {
      const filteredList = props.tableData.filter((data) => list.includes(data.asin));
      setMainTableData(filteredList);
    } else {
      setMainTableData(props.tableData);
    }
  }, [props.tableData, searchValue]);

  return (
    <>
      {errorMessage.length > 0 && (
        <CustomErrorsMessages errors={errorMessage} />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 24px",
        }}
      >
        <form
          className={props.classes.form}
          noValidate
          autoComplete="off"
          onSubmit={(e) => e.preventDefault()}
        >
          <TextField
            label="Search in the table"
            value={searchValue}
            onChange={handleChange}
          />
        </form>
        {showUpdateContentLink && (
          <Link
            component="button"
            variant="body"
            underline="hover"
            onClick={() => {
              history.push("/app/content/update", {
                asins: gridApi.getSelectedRows().map((item) => item.asin),
              });
            }}
          >
            Open Update Content for Selected Items
          </Link>
        )}
      </div>
      <div
        className="ag-theme-material"
        style={{ height: 750, width: "100%" }}
        id="#grid-theme-wrapper"
      >
        <AgGridReact
          ref={gridRef}
          rowData={mainTableData}
          suppressExcelExport={true}
          rowSelection="multiple"
          tooltipShowDelay={100}
          tooltipMouseTrack={true}
          rowHeight={60}
          defaultColDef={{
            filter: "agTextColumnFilter",
            resizable: true,
            sortable: true,
            // tooltipComponent: CustomToolTip,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          }}
          frameworkComponents={{
            buttonRenderer: ButtonRenderer,
            versionLoad: VersionLoad,
            imageCellRenderer: ImageCellRenderer,
            customToolTip: CustomToolTip,
          }}
          onGridReady={onGridReady}
          pagination={true}
          paginationPageSize={100}
          rowStyle={rowStyle}
          suppressDragLeaveHidesColumns={true}
          isRowSelectable={isRowSelectable}
          onSelectionChanged={() => {
              setShowUpdateContentLink(
                (gridApi?.getSelectedRows() || []).length > 0
              );
              props?.setTableSelectedData(gridApi?.getSelectedRows() || []);
            }
          }
          getRowStyle={getRowStyle}
        >
          <AgGridColumn
            headerName=""
            width={50}
            headerCheckboxSelection={true}
            pinned="left"
            checkboxSelection={true}
          />
          {props.headerNames.length > 0 && (
            <>
              <AgGridColumn
                headerName={"Edit"}
                cellRenderer="buttonRenderer"
                cellEditorParams={{
                  cellRenderer: "buttonRenderer",
                }}
                width={200}
              />
              <AgGridColumn
                headerName={"Version"}
                cellRenderer="versionLoad"
                cellEditorParams={{
                  cellRenderer: "versionLoad",
                }}
                width={200}
              />
              {props.headerNames.map(({ headerName, field }, index) => (
                <AgGridColumn
                  key={field}
                  headerName={headerName}
                  field={field}
                  width={190}
                  {...(headerName.includes("Image")
                    ? {
                        cellRenderer: "imageCellRenderer",
                        tooltipField: field,
                        tooltipComponent: "customToolTip",
                      }
                    : {})}
                  {...(headerName === "Product Image"
                    ? {
                        cellRenderer: renderImage,
                        tooltipField: undefined,
                        tooltipComponent: undefined,
                        field: 'asin'
                      }
                    : {})}
                />
              ))}
            </>
          )}
        </AgGridReact>
      </div>
      <Backdrop className={props.classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
});
