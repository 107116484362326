const REPORT_HEALTH_TYPES = {
  AMS_CAMPAIGN_REPORTS: "AmsCampaignReports",
  SALES_TRAFFIC_REPORTS: "SalesTrafficReports",
  VENDOR_SALES_REPORTS: "VendorSalesReports",
  AMS_ASIN_REPORTS: "AmsASINReports",
  AMS_CAMPAIGN_PLACEMENT_REPORTS: "AmsCampaignPlacementReports",
  AMS_KEYWORD_REPORTS: "AmsKeywordReport",
  AMS_SEARCH_TERM_REPORTS: "AmsSearchTermReport",
  JS_BRAND_SEGMENTS_DAY: "JSBrandSegmentsByDay",
  FORECAST_PROJECTIONS: "ForecastProjections",
  JS_SALES_ESTIMATE_DATA: "JSSalesEstimateData",
  JS_SEGMENT_BRAND_REPORTS: "JSSegmentBrandReports",
  SALES_GEO_REPORTS: "SalesGeoReports",
  SC_ORDER_METRICS_DAY: "SCOrderMetrics",
  // SC_ORDER_METRICS_WEEK: "SCOrderMetrics - week",
  SC_ORDER_METRICS_ASIN_DAY: "SCOrderMetricsASIN - day",
  SC_ORDER_METRICS_ASIN_WEEK: "SCOrderMetricsASIN - week",
  SHARE_OF_VOICE: "ShareOfVoice",
  THREE_P: 'ThreeP',
  SC_ORDER_METRICS: 'SCOrderMetrics',
  SC_ORDER_METRICS_ASIN: 'SCOrderMetricsASIN',
  INVENTORY_REPORTS: 'InventoryReports',
  VENDOR_TRAFFIC_REPORT: 'VendorTrafficReports',
  VENDOR_NET_PPM_REPORTS: 'VendorNetPPMReports'
};

const REPORTS_HEALTH = [
  {
    label: "Sales Traffic Reports",
    value: REPORT_HEALTH_TYPES.SALES_TRAFFIC_REPORTS,
  },
  {
    label: "Sales Geo Reports",
    value: REPORT_HEALTH_TYPES.SALES_GEO_REPORTS,
  },
  {
    label: "Vendor Sales Reports",
    value: REPORT_HEALTH_TYPES.VENDOR_SALES_REPORTS,
  },
  {
    label: "Vendor Traffic Reports",
    value: REPORT_HEALTH_TYPES.VENDOR_TRAFFIC_REPORT,
  },
  {
    label: "Vendor NET PPM Reports",
    value: REPORT_HEALTH_TYPES.VENDOR_NET_PPM_REPORTS,
  },
  {
    label: "Inventory Reports",
    value: REPORT_HEALTH_TYPES.INVENTORY_REPORTS,
  },
  {
    label: "AMS Campaign Reports",
    value: REPORT_HEALTH_TYPES.AMS_CAMPAIGN_REPORTS,
  },
  {
    label: "AMS ASIN Reports",
    value: REPORT_HEALTH_TYPES.AMS_ASIN_REPORTS,
  },
  {
    label: "AMS Campaign Placement Reports",
    value: REPORT_HEALTH_TYPES.AMS_CAMPAIGN_PLACEMENT_REPORTS,
  },
  {
    label: "AMS Keyword Reports",
    value: REPORT_HEALTH_TYPES.AMS_KEYWORD_REPORTS,
  },
  {
    label: "AMS Search Term Reports",
    value: REPORT_HEALTH_TYPES.AMS_SEARCH_TERM_REPORTS,
  },
  {
    label: "Forecast Projections",
    value: REPORT_HEALTH_TYPES.FORECAST_PROJECTIONS,
  },
  {
    label: "JS Brand Segments Day",
    value: REPORT_HEALTH_TYPES.JS_BRAND_SEGMENTS_DAY,
  },
  {
    label: "JS Sales Estimate Data",
    value: REPORT_HEALTH_TYPES.JS_SALES_ESTIMATE_DATA,
  },
  {
    label: "JS Segment Brand Reports",
    value: REPORT_HEALTH_TYPES.JS_SEGMENT_BRAND_REPORTS,
  },
  {
    label: "SC Order Metrics",
    value: REPORT_HEALTH_TYPES.SC_ORDER_METRICS_DAY,
  },
  // {
  //   label: "SC Order Metrics Week",
  //   value: REPORT_HEALTH_TYPES.SC_ORDER_METRICS_WEEK,
  // },
  {
    label: "SC Order Metrics ASIN Day",
    value: REPORT_HEALTH_TYPES.SC_ORDER_METRICS_ASIN_DAY,
  },
  {
    label: "SC Order Metrics ASIN Week",
    value: REPORT_HEALTH_TYPES.SC_ORDER_METRICS_ASIN_WEEK,
  },
  {
    label: "Share of Voice",
    value: REPORT_HEALTH_TYPES.SHARE_OF_VOICE,
  },
  {
    label: "Three P",
    value: REPORT_HEALTH_TYPES.THREE_P,
  },
];

const DATE_KEYS = {
  FORECAST_DATE: "forecastDate",
  REPORT_DATE: "reportDate",
  CREATED_DATE: "createdDate",
  DATE: "date",
  START_DATE: "startDate",
  END_DATE: "endDate",
  NET_PPM: "netPureProductMargin"
};

const AMS_CAMPAIGN_REPORTS_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.AMS_CAMPAIGN_REPORTS,
  id: { label: 'Campaign ID', value: "campaignId" },
  dateKey: DATE_KEYS.REPORT_DATE,
};

const SALES_TRAFFIC_REPORTS_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.SALES_TRAFFIC_REPORTS,
  dateKey: DATE_KEYS.REPORT_DATE,
};

const VENDOR_SALES_REPORTS_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.VENDOR_SALES_REPORTS,
  dateKey: DATE_KEYS.REPORT_DATE,
};

const FORECAST_PROJECTIONS_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.FORECAST_PROJECTIONS,
  dateKey: DATE_KEYS.FORECAST_DATE,
  fillMonth: true,
};

const AMS_ASIN_REPORTS_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.AMS_ASIN_REPORTS,
  id: { label: 'Campaign ID', value: "campaignId" },
  dateKey: DATE_KEYS.REPORT_DATE,
};

const AMS_CAMPAIGN_PLACEMENT_REPORTS_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.AMS_CAMPAIGN_PLACEMENT_REPORTS,
  id: { label: 'Campaign ID', value: "campaignId" },
  dateKey: DATE_KEYS.REPORT_DATE,
};

const AMS_KEYWORD_REPORTS_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.AMS_KEYWORD_REPORTS,
  id: { label: 'Campaign ID', value: "campaignId" },
  dateKey: DATE_KEYS.REPORT_DATE,
};

const AMS_SEARCH_TERM_REPORT_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.AMS_SEARCH_TERM_REPORTS,
  id: { label: 'Campaign ID', value: "campaignId" },
  dateKey: DATE_KEYS.REPORT_DATE,
};

const JS_BRAND_SEGMENTS_DAY_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.JS_BRAND_SEGMENTS_DAY,
  id: { label: 'Segment ID', value: "segmentId" },
  dateKey: DATE_KEYS.DATE,
};

const JS_SALES_ESTIMATE_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.JS_SALES_ESTIMATE_DATA,
  dateKey: DATE_KEYS.DATE,
};

const JS_SEGMENT_BRAND_REPORTS_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.JS_SEGMENT_BRAND_REPORTS,
  id: { label: 'Segment ID', value: "segmentId" },
  dateKey: DATE_KEYS.REPORT_DATE,
};

const SALES_GEO_REPORTS_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.SALES_GEO_REPORTS,
  dateKey: DATE_KEYS.REPORT_DATE,
};

const THREE_P_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.THREE_P,
  dateKey: DATE_KEYS.REPORT_DATE,
};

const SC_ORDER_METRICS_DAY_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.SC_ORDER_METRICS,
  dateKey: DATE_KEYS.START_DATE,
  granularity : 'day',
  type : 'day',
};

const SC_ORDER_METRICS_WEEK_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.SC_ORDER_METRICS,
  dateKey: DATE_KEYS.START_DATE,
  granularity : 'week',
  type : 'week',
};

const SC_ORDER_METRICS_ASIN_DAY_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.SC_ORDER_METRICS_ASIN,
  dateKey: DATE_KEYS.START_DATE,
  granularity : 'day',
  type : 'day'
};

const SC_ORDER_METRICS_ASIN_WEEK_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.SC_ORDER_METRICS_ASIN,
  dateKey: DATE_KEYS.START_DATE,
  granularity : 'week',
  type : 'week',
};

const SHARE_OF_VOICE_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.SHARE_OF_VOICE,
  dateKey: DATE_KEYS.START_DATE,
  type : 'week',

};

const INVENTORY_REPORTS_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.INVENTORY_REPORTS,
  dateKey: DATE_KEYS.REPORT_DATE,
};

const VENDOR_TRAFFIC_REPORTS_CONFIG = {
  reportType: REPORT_HEALTH_TYPES.VENDOR_TRAFFIC_REPORT,
  dateKey: DATE_KEYS.REPORT_DATE,
};

const VENDOR_NET_PPM_REPORTS = {
  reportType: REPORT_HEALTH_TYPES.VENDOR_NET_PPM_REPORTS,
  dateKey: DATE_KEYS.REPORT_DATE,
};

const CONFIG = {
  [REPORT_HEALTH_TYPES.AMS_CAMPAIGN_REPORTS]: AMS_CAMPAIGN_REPORTS_CONFIG,
  [REPORT_HEALTH_TYPES.SALES_TRAFFIC_REPORTS]: SALES_TRAFFIC_REPORTS_CONFIG,
  [REPORT_HEALTH_TYPES.VENDOR_SALES_REPORTS]: VENDOR_SALES_REPORTS_CONFIG,
  [REPORT_HEALTH_TYPES.AMS_ASIN_REPORTS]: AMS_ASIN_REPORTS_CONFIG,
  [REPORT_HEALTH_TYPES.AMS_CAMPAIGN_PLACEMENT_REPORTS]: AMS_CAMPAIGN_PLACEMENT_REPORTS_CONFIG,
  [REPORT_HEALTH_TYPES.AMS_KEYWORD_REPORTS]: AMS_KEYWORD_REPORTS_CONFIG,
  [REPORT_HEALTH_TYPES.AMS_SEARCH_TERM_REPORTS]: AMS_SEARCH_TERM_REPORT_CONFIG,
  [REPORT_HEALTH_TYPES.JS_BRAND_SEGMENTS_DAY]: JS_BRAND_SEGMENTS_DAY_CONFIG,
  [REPORT_HEALTH_TYPES.JS_SALES_ESTIMATE_DATA]: JS_SALES_ESTIMATE_CONFIG,
  [REPORT_HEALTH_TYPES.JS_SEGMENT_BRAND_REPORTS]: JS_SEGMENT_BRAND_REPORTS_CONFIG,
  [REPORT_HEALTH_TYPES.SALES_GEO_REPORTS]: SALES_GEO_REPORTS_CONFIG,
  [REPORT_HEALTH_TYPES.SC_ORDER_METRICS_DAY]: SC_ORDER_METRICS_DAY_CONFIG,
  // [REPORT_HEALTH_TYPES.SC_ORDER_METRICS_WEEK]: SC_ORDER_METRICS_WEEK_CONFIG,
  [REPORT_HEALTH_TYPES.SC_ORDER_METRICS_ASIN_DAY]: SC_ORDER_METRICS_ASIN_DAY_CONFIG,
  [REPORT_HEALTH_TYPES.SC_ORDER_METRICS_ASIN_WEEK]: SC_ORDER_METRICS_ASIN_WEEK_CONFIG,
  [REPORT_HEALTH_TYPES.SHARE_OF_VOICE]: SHARE_OF_VOICE_CONFIG,
  [REPORT_HEALTH_TYPES.THREE_P]: THREE_P_CONFIG,
  [REPORT_HEALTH_TYPES.INVENTORY_REPORTS]: INVENTORY_REPORTS_CONFIG,
  [REPORT_HEALTH_TYPES.VENDOR_TRAFFIC_REPORT]: VENDOR_TRAFFIC_REPORTS_CONFIG,
  [REPORT_HEALTH_TYPES.VENDOR_NET_PPM_REPORTS]: VENDOR_NET_PPM_REPORTS,
  [REPORT_HEALTH_TYPES.FORECAST_PROJECTIONS]: FORECAST_PROJECTIONS_CONFIG,
}

export { REPORT_HEALTH_TYPES, REPORTS_HEALTH, CONFIG };
