import { GET_API, POST_API } from "./axios"
import { filterDataByDate, modifyOrderMetricsForTable, generateHeatMapData, generateLineChartData } from "../healpers/orderMetrics.helpers";
import { modifyShareOfVoiceForTable } from "../healpers/sov.helpers";
import { modifyBrandSegmentsByDayData } from "../healpers/brandSegmentsByDay.helpers";
import { modifyMerchantListingsReportData } from "../healpers/merchantListingsReport.helpers";
import { modifyPriceAndWarData, fetchPriceWarGraphData, modifyCompetitionSalesByASIN } from "../healpers/priceAndWarHelpers";
import { tokenConfig } from '../context/UserContext';
import { BASE_URL } from "../healpers/api";
import api from "../healpers/apiRoutes";
import moment from "moment";
import { modifyProductAndReviewData } from "../healpers/productAndReviews";
import { modifyProductAndReviewDataAll } from "../healpers/productAndReviews";
import { modifyMapViolatersData } from "../healpers/mapViolaters";

/**
 * Gets the duration parameter for moment
 * @param {string} val 
 * @returns {Object} Amount and Unit
 */
const getDuration = (val) => {
    switch (val) {
        case 'Day':
            return { amount: 2, unit: 'month' }

        case 'Hour':
            return { amount: 4, unit: 'day' }

        default:
            return { amount: 2, unit: 'month' }
    }
}

/**
 * Sanitizes the params and returns valid config for API
 * @param {Object} allParams 
 * @param {string} allParams.vendorCode - Vendor Code
 * @param {string} allParams.granularity - Day/Hour
 * @param {string} allParams.asin - ASIN stands for Amazon Standard Identification Number. Almost every product on our site has its own ASIN--a unique code we use to identify it
 * @returns {Array} URL and Query Parameters
 */
const fetchOrderMetricsConfigDetails = (allParams) => {
    const { asin, ...reqParams } = allParams;
    const url = BASE_URL + ((asin && asin !== 'All') ? api.getOrderMetricsASIN : api.getOrderMetrics);
    return (asin && asin !== 'All') ? [url, allParams] : [url, reqParams]
}

/**
 * Fetches Order Metrics from the server based on vendor code and granularity
 * @param {string} vendorCode 
 * @param {string} granularity - Day/Hour
 * @param {string} asin - ASIN stands for Amazon Standard Identification Number. Almost every product on our site has its own ASIN--a unique code we use to identify it
* @returns {Array} Array of Order Metrics
 */
export const fetchOrderMetrics = async (vendorCode, granularity, asin) => {
    try {

        const allParams = { vendorCode, granularity, asin: asin?.split("-")?.[0] }
        const [url, params] = fetchOrderMetricsConfigDetails(allParams);

        const result = await GET_API(url, tokenConfig(), params);
        const data = result?.data?.data || [];
        if (Array.isArray(data)) {
            if (!data.length) {
                return {};
            } else {
                const formattedData = modifyOrderMetricsForTable(data, granularity);
                const heatmapObj = generateHeatMapData(data);
                const dateRange = [data.at(0).startDate, data.at(-1).startDate];
                const filteredData = formattedData; //filterDataByDate(formattedData, getDuration(granularity))

                return { formattedData, heatmapObj, dateRange, filteredData };
            }
        } else {
            throw new Error("Failed to fetch Order Metrics data!");
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Order Metrics from the server based on vendor code and granularity
 * @param {string} vendorCode 
 * @param {string} granularity - Day/Hour
* @returns {Array} Array of Order Metrics
 */
export const fetchOrderMetricsHour = async (vendorCode) => {
    try {
        const granularity = 'Hour'
        const allParams = { vendorCode, granularity }
        const [url, params] = fetchOrderMetricsConfigDetails(allParams);

        const result = await GET_API(url, tokenConfig(), params);
        const data = result?.data?.data || [];
        if (Array.isArray(data)) {
            if (!data.length) {
                return {};
            } else {
                const formattedData = modifyOrderMetricsForTable(data, granularity);
                const filteredData = filterDataByDate(formattedData, getDuration(granularity))
                const lineChartData = generateLineChartData(data);
                return { formattedData, lineChartData, filteredData };
            }
        } else {
            throw new Error("Failed to fetch Order Metrics data!");
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches List of ASINs from the server based on vendor code and granularity
 * @param {string} vendorCode 
 * @param {string} asin - ASIN stands for Amazon Standard Identification Number. Almost every product on our site has its own ASIN--a unique code we use to identify it
 * @returns {Array} Array of ASINs
 */
export const fetchASINs = async (vendorCode, reportType = 'ordermetricsasinday') => {
    try {

        if(!vendorCode) {
            return [];
        }
        const url = BASE_URL + api.getASINs;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, reportType }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || '';

        if (!data.length) {
            return [];
        } else {
            return data.split(',');
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches SOV data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of SOV data
 */
export const fetchShareOfVoiceData = async (vendorCode, startDate, endDate, searchTerm) => {
    try {
        const url = BASE_URL + api.getShareOfVoice;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, startDate, endDate, searchTerm }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        if (!data) {
            return {};
        } else {
            const formattedData = modifyShareOfVoiceForTable(data);
            const filteredData = filterDataByDate(formattedData, {}, startDate, endDate)

            return { filteredData };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}


/**
 * Fetches SOV data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of SOV data
 */
export const fetchSOVRanges = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getShareOfVoiceRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }
        const result = await GET_API(url, config.headers, config.params);
        const ranges = result?.data?.data || {};
        if (!ranges.dates || !ranges.searchTerm) {
            throw new Error('Failed to fetch date ranges and search terms')
        } else {
            const dates = (ranges?.dates || []).map(ele => { return { startDate: moment(ele.startDate).format('MM/DD/YYYY'), endDate: moment(ele.endDate).format('MM/DD/YYYY') } })
            return { dates, sTerms: (ranges?.searchTerm || '').split(',') };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches date range data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of dates
 */
 export const fetchDateRanges = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getBrandSegmentByDayRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }
        const result = await GET_API(url, config.headers, config.params);
        const ranges = result?.data?.data || {};
        if (!ranges?.dates) {
            throw new Error('Failed to fetch date ranges and search terms')
        } else {
            const dates = (ranges?.dates || []).map(ele => { return { date: moment(ele.date).format('MM/DD/YYYY') } })
            return { dates };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}


/**
 * Fetches Brand Segments from the server based on vendor code and date
 * @param {string} vendorCode 
 * @param {string} date
* @returns {Array} Array of Order Metrics
 */
export const fetchBrandSegmentsByDayData = async (vendorCode, date) => {
    try {
        const url = BASE_URL + api.getBrandSegmentByDay;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, date }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        if (!data) {
            return {};
        } else {
            const formattedData = modifyBrandSegmentsByDayData(data);
            // const filteredData = filterBrandSegmentDataByDate(formattedData, date)

            return { filteredData: formattedData };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Keywords By ASINS ranges from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of Keywords By ASINS ranges  */
 export const fetchKeywordsByASINRanges = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getKeywordsByASINRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }
        const result = await GET_API(url, config.headers, config.params);
        const ranges = result?.data?.data || {};
        if (!ranges.months || !ranges.asins) {
            throw new Error('Failed to fetch date ranges and search terms')
        } else {
            const months = (ranges?.months || []).map(ele => ele.month)
            return { months, asins: (ranges?.asins || '').split(',') };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches SOV data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of SOV data
 */
 export const fetchKeywordsByASINData = async (vendorCode, month, asin) => {
    try {
        const url = BASE_URL + api.getKeywordsByASIN;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, month, asin }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        if (!data) {
            return {};
        } else {
            // const formattedData = modifyShareOfVoiceForTable(data);
            // const filteredData = filterDataByDate(formattedData, {}, month)

            return { filteredData: data };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Order Metrics from the server based on vendor code and granularity
 * @param {string} vendorCode 
 * @param {string} date
* @returns {Array} Array of Order Metrics
 */
export const fetchMerchantListingsFYPReport = async (vendorCode, date) => {
    try {
        const url = BASE_URL + api.getMerchantListingsReport;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        if (!data) {
            return {};
        } else {
        	 const filteredData = modifyMerchantListingsReportData(data);
            return { filteredData };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Sales Estimate ranges from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of asins and dates  */
 export const fetchSalesEstimateRanges = async (vendorCode) => {
    try {
        if(!vendorCode) {
            return {}
        }
        const url = BASE_URL + api.getSalesEstimateDataRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }
        const result = await GET_API(url, config.headers, config.params);
        const ranges = result?.data?.data || {};
        if (!ranges.dates || !ranges.asins) {
            throw new Error('Failed to fetch date ranges and search terms')
        } else {
            const dates = (ranges?.dates || []).map(ele => moment(ele.date).format('MM/DD/YYYY'))
            return { dates, asins: (ranges?.asins || '').split(',') };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches SOV data from the server based on vendor code
 * @param {string} vendorCode 
 * @param {string} date 
 * @param {string} asin 
 * @returns {Array} Array of SOV data
 */
 export const fetchSalesEstimateData = async (vendorCode, date) => {
    try {
        const url = BASE_URL + api.getSalesEstimateData;

        let params = { vendorCode, date };
        const config = {
            ...tokenConfig(),
            params: params
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        const filteredData = data.map((ele) => {
            return { ...ele, lastKnownPrice: `$${ele?.lastKnownPrice}` }
        })
        if (!data) {
            return {};
        } else {
            return { filteredData };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Price War data from the server based on vendor code
 * @param {string} date 
 * @param {string} segment 
 * @returns {Array} Array of SOV data
 */
 export const fetchPriceWarData = async (interval, segmentId, vendorCode) => {
    try {
        const url = BASE_URL + api.getPriceWarData;
        const [startDate, endDate] = interval.split("-");
        let params = { 
            startDate,
            endDate,
            vendorCode,
            segmentId };
        const config = {
            ...tokenConfig(),
            params: params
        }

        const result = await GET_API(url, config.headers, config.params);
        const { priceWarData, asins, productDetails } = result?.data?.data || [];
        const filteredData = modifyPriceAndWarData(asins, priceWarData, productDetails)
        const priceWarGraphData = fetchPriceWarGraphData(asins, priceWarData)
        if (!filteredData) {
            return {};
        } else {
            return { filteredData, priceWarGraphData };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Price War ranges from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of intervals and segment Ids  */
export const fetchPriceWarRanges = async (vendorCode) => {
    try {

        if(!vendorCode) {
            return {}
        }
        const url = BASE_URL + api.getPriceWarDataRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }
        const result = await GET_API(url, config.headers, config.params);
        const ranges = result?.data?.data || {};
        if (!ranges.intervals) {
            throw new Error('Failed to fetch date ranges and search terms')
        } else {
            return { ...ranges };
        }
    } catch (err) {
        console.log('Price War Ranges', err)
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Brand Segments from the server based on vendor code and date
 * @param {string} vendorCode 
 * @returns {Array} Array of ASINs and their Projections
 */
export const fetchASINPIR = async (vendorCode, granularity, endDate) => {
    try {
        const url = BASE_URL + api.getOrderMetricsASINPIR;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, granularity, endDate }
        }

        const result = await GET_API(url, config.headers, config.params);
        let data = result?.data?.data || [];
        data = data.map(ele => { return { ...ele, pir: Math.round(+ele?.pir) } })
        if (!data) {
            return {};
        } else {

            return { filteredData: data };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Brand Segments from the server based on vendor code and date
 * @param {string} vendorCode 
 * @returns {Array} Array of Ranges
 */
 export const fetchASINPIRRanges = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getOrderMetricsASINPIRRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, granularity: 'week' }
        }

        const result = await GET_API(url, config.headers, config.params);
        let data = result?.data?.data?.dates || [];
        data = data.map(ele => moment(ele.startDate).format('MM/DD/YYYY'))
        if (!data) {
            return {};
        } else {
            return data;
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches SOV data from the server based on vendor code
 * @param {string} date 
 * @param {string} segment 
 * @returns {Array} Array of SOV data
 */
export const fetchCompetitionSalesByASIN = async (vendorCode, interval, segment) => {
    try {
        const url = BASE_URL + api.getCompetitionSalesByASIN;
        const [startDate, endDate] = interval.split("-");
        let params = {
            vendorCode,
            startDate,
            endDate,
            segment
        };
        const config = {
            ...tokenConfig(),
            params: params
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.aggData || [];
        const filteredData = modifyCompetitionSalesByASIN(data)
        if (!filteredData) {
            return {};
        } else {
            return { filteredData };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

function compare(a, b) {
    if (a?.parentASIN < b?.parentASIN) {
        return -1;
    }
    if (a?.parentASIN > b?.parentASIN) {
        return 1;
    }
    return 0;
}

/**
 * Fetches Product and Reviews data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of SOV data
 */
 export const fetchProductAndReviews = async (vendorCode, interval, parentASIN = null) => {
    try {
        const url = BASE_URL + api.getProductReviews;
        // const [startDate, endDate] = interval.split("-");
        let params = { 
            // startDate,
            // endDate,
            dateRange: interval?.split(" - ").reverse(),
            parentASIN,
            vendorCode };
        const config = {
            ...tokenConfig(),
            params: params
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = (result?.data?.data || []).map(ele => { return { ...ele, parentASIN: ele?.asin?.original } }).sort(compare);
        const filteredData = modifyProductAndReviewData(data, parentASIN)
        if (!filteredData) {
            return [{}, {}];
        } else {
            return [filteredData, data];
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Price War ranges from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of intervals and segment Ids  */
 export const fetchProductAndReviewsRanges = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getProductReviewsRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || {};

        const minDate = data?._min?.date || '';
        const maxDate = data?._max?.date || '';
        
        let endDate = moment(maxDate);
        let startDate = moment(minDate);

        const dates = [];
        while (startDate < endDate) {
            let interval = `${startDate.format('MM/DD/YYYY')} - ${moment(startDate).add(7, 'day').format('MM/DD/YYYY')}`
            dates.push(interval);
            startDate.add(7, 'day');
        }
        if (!data) {
            return {};
        } else {

            return dates.reverse();
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches all Product and Reviews data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of SOV data
 */
 export const fetchProductAndReviewsAll = async (vendorCode, parentASIN = null) => {
    try {
        const url = BASE_URL + api.getProductReviewsAll;
        let params = { 
            vendorCode };
        const config = {
            ...tokenConfig(),
            params: params
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        const filteredData = modifyProductAndReviewDataAll(data, parentASIN)
        if (!filteredData) {
            return [{}, {}];
        } else {
            return [filteredData, data ];
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Map Violaters ranges from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of dates  */
export const fetchMapViolatersRange = async (vendorCode) => {
    try {

        if(!vendorCode) {
            return []
        }
        const url = BASE_URL + api.getMapViolaterRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        if (!data) {
            return {};
        } else {

            return data;
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Map Violaters data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of map violaters  */
 export const fetchMapViolaters = async (vendorCode, selectedDate) => {
    try {
        const url = BASE_URL + api.getMapViolaters;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, selectedDate }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        if (!data) {
            return [];
        } else {
            return modifyMapViolatersData(data);
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Map Violaters data from the server based on vendor code
 * @param {Array} data 
 * @returns {Object} Array of map violaters  */
 export const writeASINSKUMapping = async (mapData) => {
    try {
        const url = BASE_URL + api.uploadASINSKUMapping;

        const config = {
            ...tokenConfig()
        }

        const result = await POST_API(url, config.headers, mapData);
        const data = result?.data?.message || [];
        if (!data) {
            return [];
        } else {
            return data
        }
    } catch (err) {
        return []
    }
}

/**
 * Fetches Map Violaters ranges from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of dates  */
 export const fetchOrderMetricsStats = async (vendorCode, reportType) => {
    try {

        if(!vendorCode || !reportType) {
            return []
        }
        const url = BASE_URL + api.getOrderMetricsStats;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, reportType }
        }

        const result = await GET_API(url, config.headers, config.params);
        let data = result?.data?.data || {};
        data = {
            ...data,
            startDate: moment(data?._min?.startDate).format('MM/DD/YYYY'),
            endDate: moment(data?._max?.startDate).format('MM/DD/YYYY'),
            reportType 
        }
        if (!data || !data?._min?.startDate || !data?._max?.startDate) {
            return [];
        } else {

            return [[data], data?.maxDuration];
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Map Violaters ranges from the server based on vendor code
 * @param {string} vendorCode 
 * @param {string} reportType 
 * @param {string} startDate 
 * @param {string} endDate 
 * @returns {Object} Pulls Order MetricsData into DB  */
 export const generateOrderMetricsData = async (vendorCode, reportType, startDate, endDate) => {
    try {

        if(!vendorCode || !reportType || !startDate || !endDate) {
            return []
        }
        const url = BASE_URL + api.pullOrderMetricsData;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, reportType, startDate, endDate }
        }

        const result = await GET_API(url, config.headers, config.params);
        return result?.data || {};
    } catch (err) {
        throw new Error(err.message || err)
    }
}

export const uploadAsinMapPrice = async (uploadData) => {
  try {
    const url = BASE_URL + api.uploadASINMapPrice;

    const config = {
      ...tokenConfig(),
    };

    const result = await POST_API(url, config.headers, uploadData);
    const data = result?.data?.message || [];
    if (!data) {
      return [];
    } else {
      return data;
    }
  } catch (err) {
    return [];
  }
};

/**
 * Fetches Reach Trend from the server based on vendor code
 * @param {string} vendorCode 
 * @param {string} startDate 
 * @param {string} endDate 
 * @returns {Object} Pulls Order MetricsData into DB  */
 export const fetchReachTrendData = async (vendorCode, startDate, endDate) => {
    try {

        if(!vendorCode || !startDate || !endDate) {
            return []
        }
        const url = BASE_URL + api.getReachTrend;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, startDate, endDate }
        }

        const result = await GET_API(url, config.headers, config.params);
        return result?.data || {};
    } catch (err) {
        throw new Error(err.message || err)
    }
}

export const getVendorSettings = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getVendorSettings;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }

        const result = await GET_API(url, config.headers, config.params);
        return result?.data || {};
    } catch (err) {
        throw new Error(err.message || err)
    }
}

export const getSubCategories = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getSubCategories;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }

        const result = await GET_API(url, config.headers, config.params);
        return result?.data || {};
    } catch (err) {
        throw new Error(err.message || err)
    }
}

export const saveVendorSettings = async (vendorSettings) => {
    try {
        const url = BASE_URL + api.saveVendorSettings;

        const config = {
            ...tokenConfig(),
        }

        const result = await POST_API(url, config.headers, vendorSettings);
        return result?.data || {};
    } catch (err) {
        throw new Error(err.message || err)
    }
}

export const updateVendorSettings = async (vendorSettings) => {
    try {
        const url = BASE_URL + api.updateVendorSettings;

        const config = {
            ...tokenConfig(),
        }

        const result = await POST_API(url, config.headers, vendorSettings);
        return result?.data || {};
    } catch (err) {
        throw new Error(err.message || err)
    }
}

export const saveProductCatalogs = async (body) => {
    try {
        const url = BASE_URL + api.saveProductCatalogs;

        const config = {
            ...tokenConfig(),
        }

        const result = await POST_API(url, config.headers, body);
        return result?.data || {};
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches SubCategories from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of SubCategories name  */
 export const fetchAMSSubCategories = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getAMSSubCategories;
        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
       
        return data;
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches ASINs from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of SubCategories name  */
export const fetchCatalogASINs = async (vendorCode) => {
    try {
        const url = BASE_URL + api.catalogGetAPI;
        const config = {
            ...tokenConfig(),
            params: { vendorCode, marketplaceId: "ATVPDKIKX0DER" }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
       
        return data;
    } catch (err) {
        throw new Error(err.message || err)
    }
}
/**
 * Fetches Products from the server based on vendor code and subCategories
 * @param {string} vendorCode 
 * @param {Object} subCategories 
 * @returns {Object} Array of products */
 export const fetchAMSProducts = async (vendorCode,subCategories) => {
    try {
        const url = BASE_URL + api.getAMSProducts;
        const config = {
            ...tokenConfig(),
            params: { vendorCode ,subCategories}
        }
        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        
        return data;
    } catch (err) {
        throw new Error(err.message || err)
    }
}

export const fetch3PReport = async (vendorCode, startDate, endDate) => {
  try {
    const url = BASE_URL + api.pullThreePReport;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchCampaignReports = async (vendorCode) => {
  try {
    const url = BASE_URL + api.fetchCampaignReports;
    const config = { params: { vendorCode }};
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchShareOfVoiceReport = async (vendorCode, startDate, endDate) => {
  try {
    const url = BASE_URL + api.pullShareOfVoiceReport;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchGeographicInsightsReport = async (
  vendorCode,
  startDate,
  endDate
) => {
  try {
    const url = BASE_URL + api.pullGeoReports;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchVendorSalesReport = async (
  vendorCode,
  startDate,
  endDate
) => {
  try {
    const url = BASE_URL + api.pullVendorSalesReport;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchJSSegmentHistory = async (
    segmentId,
    startDate,
    endDate
  ) => {
    try {
      const url = BASE_URL + api.pullJSSegmentReports;
      const config = { 
        ...tokenConfig(),
        params: { segmentId, startDate, endDate }
      };
      const result = await GET_API(url, config.headers, config.params);
      return result.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
    }
  };

export const fetchSalesTrafficReports = async (
  vendorCode,
  startDate,
  endDate
) => {
  try {
    const url = BASE_URL + api.fetchSalesTrafficReports;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const catalogRefresh = async (vendorCode) => {
  try {
    const url = BASE_URL + api.catalogRefresh;
    const config = { 
        ...tokenConfig(),
        params: { vendorCode } 
    };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchSearchTermsReport = async () => {
    try {
        const url = BASE_URL + api.fetchSearchTermsReport;
        const config = tokenConfig();
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchKeywordsReport = async () => {
    try {
        const url = BASE_URL + api.fetchKeywordsReport;
        const config = tokenConfig();
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchDapiStatistics = async (month, year) => {
    try {
        const url = BASE_URL + api.fetchDapiStatistics;
        const config = {
            params: { month, year }
        };
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchCohesityOptions = async () => {
    try {
        const url = BASE_URL + api.cohesityOptions;
        const config = tokenConfig();
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};


export const fetchWBROptions = async () => {
    try {
        const url = BASE_URL + api.wbrOptions;
        const config = tokenConfig();
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchCustomerConfig = async (vendorCode) => {
    try {
        const url = BASE_URL + api.fetchCustomerConfig;
        const config = {
            ...tokenConfig(),
            params: { vendorCode },
        }
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchDbArchives = async () => {
    try {
        const url = BASE_URL + api.fetchDbArchives;
        const result = await GET_API(url);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const restoreDbArchive = async (body) => {
    try {
        const url = BASE_URL + api.restoreDbArchive;
        const config = {};
        const result = await POST_API(url, config.headers, body);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchAllSpNotificationsSubscriptions = async () => {
    try {
        const url = BASE_URL + api.fetchAllSpNotificationsSubscriptions;
        const config = {
            ...tokenConfig(),
        };
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchSpNotificationsSubscriptions = async (vendorCode) => {
    try {
        const url = BASE_URL + api.spNotificationsSubscriptions;
        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        };
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const updateSpNotificationsSubscriptions = async (vendorCode, body) => {
    try {
        const url = BASE_URL + api.spNotificationsSubscriptions + `?vendorCode=${vendorCode}`;
        const config = {
            ...tokenConfig(),
        };
        const result = await POST_API(url, config.headers, { notifications: body });
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const updateCohesityOptions = async (body) => {
    try {
        const url = BASE_URL + api.cohesityOptions;
        const config = {
            ...tokenConfig(),
        };
        const result = await POST_API(url, config.headers, { cohesityData: body });
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const updateWBROptions = async (body) => {
    try {
        const url = BASE_URL + api.wbrOptions;
        const config = {
            ...tokenConfig(),
        };
        const result = await POST_API(url, config.headers, body);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchDetailPages = async (vendorCode) => {
    try {
        const url = `${BASE_URL + api.detailPage}/${vendorCode}`;
        const config = {
            ...tokenConfig(),
        };
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const updateDetailPageBaselineDetails = async (asin) => {
    try {
        const url = `${BASE_URL + api.detailPageBaselineDetails}/${asin}`;
        const config = {
            ...tokenConfig(),
        };
        const result = await POST_API(url, config.headers, {});
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchWbrFinanceReport = async (vendorCode) => {
    try {
        const url = BASE_URL + api.fetchWbrFinanceReport + `/${vendorCode}`;
        const result = await GET_API(url);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchWbrOperationsReport = async (params) => {
    try {
        const url = BASE_URL + api.fetchWbrOperationsReport;
        const config = {
            ...tokenConfig(),
            params,
        }
        console.log('fetchWbrOperationsReport params', params);
        const result = await GET_API(url,config.headers, params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const uploadWbrFinance = async (financeDisputes) => {
    try {
        const url = BASE_URL + api.uploadFinanceDisputes;
        const config = {
            ...tokenConfig(),
        };
        const result = await POST_API(url, config.headers, { financeDisputes });
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const uploadWbrOperations = async (operationsTickets) => {
    try {
        const url = BASE_URL + api.uploadOperationsTickets;
        const config = {
            ...tokenConfig(),
        };
        const result = await POST_API(url, config.headers, { operationsTickets });
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchContents = async (vendorCode) => {
  try {
    const url = `${BASE_URL + api.fetchContents}/${vendorCode}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchApproveContents = async (vendorCode, isInternal = false) => {
  try {
    const url = isInternal
      ? `${BASE_URL + api.fetchInternalContents}/${vendorCode}`
      : `${BASE_URL + api.fetchCustomerContents}/${vendorCode}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchTopKeywordsByASINs = async (
  asins,
  competitorAsins,
  country,
  limit = 10
) => {
  try {
    const url = `${BASE_URL + api.fetchTopKeywordsByASINs}`;
    const config = {
      ...tokenConfig(),
      params: { asins, competitorAsins, country, top: limit },
    };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchRelevantASINs = async (vendorCode, asins, country, onboarding) => {
  try {
    const url = `${BASE_URL + api.fetchRelevantASINs}`;
    const config = {
      ...tokenConfig(),
      params: { vendorCode, asins, country, onboarding },
    };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    return err?.response?.data;
  }
};

export const fetchCompetitorASINs = async (asins, country) => {
  try {
    const url = `${BASE_URL + api.fetchCompetitorASINs}?asins=${asins}&country=${country}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const generateProductName = async (titles) => {
  try {
    const url = `${BASE_URL + api.generateProductName}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {
      titles,
    });
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const generateContentsReviewsSummary = async (asins, country, rating, sort, count) => {
  try {
    const url = `${BASE_URL + api.generateReviewsSummary}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {
      asins,
      country,
      rating,
      sort,
      count,
    });
    return result.data;
  } catch (err) {
    return err?.response?.data;
  }
};

export const generateContents = async (vendorCode, asins, chatGptPrompt) => {
  try {
    const url = `${BASE_URL + api.generateContents}/${vendorCode}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {
      asins,
      chatGptPrompt,
    });
    return result.data;
  } catch (err) {
    return err?.response?.data;
  }
};

export const fetchContentRealtimeInfo = async (asins, country) => {
  try {
    const url = `${BASE_URL + api.fetchContentRealTimeInfo}?asins=${asins}&country=${country}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const saveContents = async (vendorCode, contents, action = "") => {
  try {
    const url = `${BASE_URL + api.saveContents}/${vendorCode}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {
      contents,
      action,
    });
    return result.data;
  } catch (err) {
    return err?.response?.data;
  }
};

export const sendContentsMail = async (vendorCode, asins, mail) => {
  try {
    const url = `${BASE_URL + api.sendContentsMail}/${vendorCode}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {
      asins,
      mail,
    });
    return result.data;
  } catch (err) {
    return err?.response?.data;
  }
};

export const deleteContent = async (vendorCode, asin) => {
  try {
    const url = `${BASE_URL + api.deleteContent}/${vendorCode}/${asin}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {});
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchContentChangelogs = async (vendorCode) => {
  try {
    const url = `${BASE_URL + api.fetchContentChangelogs}/${vendorCode}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {});
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};  

export const saveContentChangelogs = async (vendorCode, changelogs) => {
  try {
    const url = `${BASE_URL + api.saveContentChangelogs}/${vendorCode}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {
      changelogs,
    });
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const deactivateAccount = async (vendorCode, option) => {
  try {
    const url = `${BASE_URL + api.deactivateAccount}/${vendorCode}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {
      option,
    });
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const mergeAccount = async (vendorCode, aliasVendorCode) => {
  try {
    const url = `${BASE_URL + api.mergeAccount}/${vendorCode}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {
      aliasVendorCode,
    });
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchVendorTrafficReport = async (
    vendorCode,
    startDate,
    endDate
  ) => {
    try {
      const url = BASE_URL + api.pullVendorTrafficReport;
      const config = { params: { vendorCode, startDate, endDate } };
      const result = await GET_API(url, config.headers, config.params);
      return result.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
    }
  };
  
export const fetchCohesityAsins = async (vendorCode) => {
  try {
    const url = BASE_URL + api.cohesityAsins + `/${vendorCode}`;
    const config = {
        ...tokenConfig(),
      };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const saveCohesityAsins = async (vendorCode, asins) => {
  try {
    const url = BASE_URL + api.cohesityAsins + `/${vendorCode}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {
      asins,
    });
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchWbrConfigs = async (params) => {
    try {
      const url = BASE_URL + api.getWbrConfig
      const config = {
        ...tokenConfig(),
        params
      };
      const result = await GET_API(url, config.headers, config.params);
      return result.data;
    } catch (err) {
      throw new Error(err.response?.data?.message);
    }
  };

export const fetchUserGroups = async () => {
  try {
    const url = `${BASE_URL + api.fetchUserGroups}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await GET_API(url, config.headers);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchUserGroupNames = async () => {
  try {
    const url = `${BASE_URL + api.fetchUserGroupNames}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await GET_API(url, config.headers);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const saveUserGroup = async (groupName, userEmails) => {
  try {
    const url = `${BASE_URL + api.saveUserGroup}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {
      userGroups: userEmails.map((userEmail) => ({
        groupName,
        userEmail,
      })),
    });
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const updateUserGroup = async (editGroupName, groupName, userEmails) => {
  try {
    const url = `${BASE_URL + api.updateUserGroup}` + editGroupName;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {
      userGroups: userEmails.map((userEmail) => ({
        groupName,
        userEmail,
      })),
    });
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const deleteUserGroup = async (groupName) => {
  try {
    const url = `${BASE_URL + api.deleteUserGroup}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {
      groupName,
    });
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchAmNotificationOptions = async () => {
    try {
      const url = BASE_URL + api.amNotificationOptions;
      const config = tokenConfig();
      const result = await GET_API(url, config.headers, config.params);
      return result.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
    }
  };
  
  export const saveAmNotificationOptions = async (
    vendorCode,
    notificationOptions
  ) => {
    try {
      const url = `${BASE_URL + api.amNotificationOptions}/${vendorCode}`;
      const config = tokenConfig();
      const result = await POST_API(url, config.headers, {
        notificationOptions,
      });
      return result.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
    }
  };
  
  export const fetchCategoriesList = async (params) => {
    try {
        const url = `${BASE_URL + api.categoryList}`;
        const config = tokenConfig(params)
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const checkIfReportExists = async (params) => {
    try {
        const url = `${BASE_URL + api.findIfReportsExist}`;
        const config = tokenConfig(params)
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        console.log('checkIfReportExists',err)
        return err.response?.data?.data
    }
};

export const fetchPromotionPerformanceReport = async (
  vendorCode,
  startDate,
  endDate
) => {
  try {
    const url = BASE_URL + api.pullPromotionPerformanceReport;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchCouponPerformanceReport = async (
  vendorCode,
  startDate,
  endDate
) => {
  try {
    const url = BASE_URL + api.pullCouponPerformanceReport;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchProductPricingReport = async (
  vendorCode,
  startDate,
  endDate
) => {
  try {
    const url = BASE_URL + api.pullProductPricingReport;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchInventoryReports = async (
  vendorCode,
  startDate,
  endDate
) => {
  try {
    const url = BASE_URL + api.pullInventoryReport;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchViews = async (params) => {
  try {
      const url = `${BASE_URL + api.views}`;
      const config = tokenConfig(params)
      const result = await GET_API(url, config.headers, config.params);
      return result.data;
  } catch (err) {
      console.log('fetchViews',err.response?.data?.message)
      throw new Error(err.response?.data?.message)
  }
}

export const configureAmRuleDefault = async (vendorCode) => {
  try {
    const url = BASE_URL + api.configureDefaultRule;
    const config = { params: { vendorCode } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchVendorNPPMarginReport = async (
  vendorCode,
  startDate,
  endDate
) => {
  try {
    const url = BASE_URL + api.pullVendorNPPMarginReport;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchSalesFunnelReports = async (params) => {
  try {
      const url = `${BASE_URL + api.fetchSalesFunnelReports}`;
      const config = tokenConfig(params)
      const result = await GET_API(url, config.headers, config.params);
      return result.data;
  } catch (err) {
      throw new Error(err.response?.data?.message)
  }
};

export const uploadWbrShortageClaimsAndChargebacks = async (data, type) => {
  try {
      const url = BASE_URL + api.uploadWbrShortageClaimsAndChargebacks;
      const config = {
          ...tokenConfig(),
      };
      const result = await POST_API(url, config.headers, { data, type });
      return result.data;
  } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchChargebacks = async (vendorCode) => {
  try {
      const url = BASE_URL + api.fetchChargebacks + `/${vendorCode}`;
      const result = await GET_API(url);
      return result.data;
  } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchShortageClaims = async (vendorCode) => {
  try {
      const url = BASE_URL + api.fetchShortageClaims + `/${vendorCode}`;
      const result = await GET_API(url);
      return result.data;
  } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
  }
};

export const pullVendorRealTimeSalesReport = async (
  vendorCode,
) => {
  try {
    const url = BASE_URL + api.pullVendorRealTimeSalesReport;
    const config = { params: { vendorCode } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const pullVendorRealTimeTrafficReport = async (
  vendorCode,
) => {
  try {
    const url = BASE_URL + api.pullVendorRealTimeTrafficReport;
    const config = { params: { vendorCode } };
    console.log('url', url);
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const pullVendorRealTimeInventoryReport = async (
  vendorCode,
) => {
  try {
    const url = BASE_URL + api.pullVendorRealTimeInventoryReport;
    const config = { params: { vendorCode } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const updateAMRulesConfig = async (data) => {
  try {
    const url = BASE_URL + api.updateAMRulesConfig;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, data);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchMbrExport = async (vendorCode) => {
  try {
    const url = BASE_URL + api.fetchMbrExport + `?vendorCode=${vendorCode}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await GET_API(url, config.headers);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const generateCommissionsExport = async (vendorCode) => {
  try {
    const url = BASE_URL + api.generateCommissionsExport + `?vendorCode=${vendorCode}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await GET_API(url, config.headers);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const updateBIAccess = async (body) => {
  try {
      const url = BASE_URL + api.biAccess;
      const config = {
          ...tokenConfig(),
      };
      const result = await POST_API(url, config.headers, body);
      return result.data;
  } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
  }
};

export const saveReviewsAsinsApiConfig = async (vendorCode, asinConfig) => {
  try {
      const url = BASE_URL + api.saveAsinsReviewsApiConfig;
      const config = {
          ...tokenConfig(),
      };
      const result = await POST_API(url, config.headers, {
        vendorCode,
        asinConfig
      });
      return result.data;
  } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
  }
};

export const saveAsinsApiConfig = async (vendorCode) => {
  try {
      const url = BASE_URL + api.saveAsinsApiConfig;
      const config = {
          ...tokenConfig(),
      };
      const result = await POST_API(url, config.headers, {
        vendorCode,
      });
      return result.data;
  } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
  }
};

export const saveSegmentsApiConfig = async (vendorCode) => {
  try {
      const url = BASE_URL + api.saveSegmentsApiConfig;
      const config = {
          ...tokenConfig(),
      };
      const result = await POST_API(url, config.headers, {
        vendorCode
      });
      return result.data;
  } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
  }
};

export const saveShareOfVoiceApiConfig = async (configs) => {
  try {
      const url = BASE_URL + api.saveShareOfVoicesApiConfig;
      const config = {
          ...tokenConfig(),
      };
      const result = await POST_API(url, config.headers, {
        configs
      });
      return result.data;
  } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchWbrFunnelData = async (vendorCode, filter, filterValue, period, startDate, endDate) => {
  try {
    const url = BASE_URL + api.fetchWbrFunnel + `?vendorCode=${vendorCode}&&filter=${filter}&&filterValue=${filterValue}&&period=${period}&&startDate=${startDate}&endDate=${endDate}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await GET_API(url, config.headers);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchSegmentsList = async (params) => {
  try {
      const url = `${BASE_URL + api.segmentList}`;
      const config = tokenConfig(params)
      const result = await GET_API(url, config.headers, config.params);
      return result.data;
  } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
  }
};

export const uploadS3CatalogFavicon = async (vendorCode) => {
  try {
      const url = BASE_URL + api.uploadS3CatalogProductImages;
      const config = {
          ...tokenConfig(),
      };
      const result = await POST_API(url, config.headers, {
        vendorCode
      });
      return result.data;
  } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
  }
};

export const uploadS3JsCompetitorFavicon = async (vendorCode) => {
  try {
      const url = BASE_URL + api.uploadS3JsCompetitorProductImages;
      const config = {
          ...tokenConfig(),
      };
      const result = await POST_API(url, config.headers, {
        vendorCode
      });
      return result.data;
  } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
  }
};

export const generateReviews = async (vendorCode) => {
  try {
      const url = BASE_URL + api.generateReviews;
      const config = {
          ...tokenConfig({
            vendorCode
          }),
      };
      const result = await GET_API(url, config.headers, config.params);
      return result.data;
  } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
  }
};

export const deleteReportCache = async (vendorCode, cacheName) => {
  try {
    const url = `${BASE_URL + api.deleteAccountReportCache}/${vendorCode}?cacheName=${cacheName}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {});
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const createReportCache = async (vendorCode, cacheName) => {
  try {
    const url = `${BASE_URL + api.createAccountReportCache}/${vendorCode}?cacheName=${cacheName}`;
    const config = {
      ...tokenConfig(),
    };
    const result = await POST_API(url, config.headers, {});
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

