import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
import { numberWithCommas, renderFavicon } from "../../../../healpers/utilityFunctions";

export default forwardRef(function Tables(props, ref) {
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const rowStyle = { background: 'white' };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const FavIconRenderer = (iconProps) => {
    return (
      <img width={50} src={`https://sd-catalog-images.s3.amazonaws.com/${iconProps.data.asin}.jpg`} alt={iconProps.data.asin}/>
    );
  };

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          faviconRenderer: FavIconRenderer,
        }}
      >
        <AgGridColumn>
          <AgGridColumn headerName={''} field={'asin'} width={100} cellRenderer={({value}) => renderFavicon(value)} pinned='left'/>
          <AgGridColumn headerName={'ASIN'} field={'asin'} width={150} pinned='left'/>
          {/* <AgGridColumn headerName={'SKU'} field={'SKU'} width={150}/> */}
          {/* <AgGridColumn headerName={'Title'} field={'Title'} width={300}/> */}
        </AgGridColumn>
        <AgGridColumn headerName="Current Week">
          <AgGridColumn field={'current_week_revenue'} headerName={'Shipped Revenue'} width={150} valueFormatter={({value}) => numberWithCommas(value, 2)}/>
          <AgGridColumn field={'current_week_totalUnits'} headerName={'Shipped Units'} width={150}/>
          <AgGridColumn field={'current_week_pageViews'} headerName={'Glance Views'} width={150}/>
          <AgGridColumn field={'current_week_conversion'} headerName={'Conversion Rate'} width={150} valueFormatter={({value}) => numberWithCommas(value, 2)}/>
          <AgGridColumn field={'current_week_asp'} headerName={'ASP'} width={150} valueFormatter={({value}) => numberWithCommas(value, 2)}/>
        </AgGridColumn>
        <AgGridColumn headerName="Previous Week">
          <AgGridColumn field={'previous_week_revenue'} headerName={'Shipped Revenue'} width={150} valueFormatter={({value}) => numberWithCommas(value, 2)}/>
          <AgGridColumn field={'previous_week_totalUnits'} headerName={'Shipped Units'} width={150}/>
          <AgGridColumn field={'previous_week_pageViews'} headerName={'Glance Views'} width={150}/>
          <AgGridColumn field={'previous_week_conversion'} headerName={'Conversion Rate'} width={150} valueFormatter={({value}) => numberWithCommas(value, 2)}/>
          <AgGridColumn field={'previous_week_asp'} headerName={'ASP'} width={150} valueFormatter={({value}) => numberWithCommas(value, 2)}/>
        </AgGridColumn>
        <AgGridColumn headerName="Current Week - 2 ">
          <AgGridColumn field={'previous_week_revenue'} headerName={'Shipped Revenue'} width={150} valueFormatter={({value}) => numberWithCommas(value, 2)}/>
          <AgGridColumn field={'previous_week_totalUnits'} headerName={'Shipped Units'} width={150}/>
          <AgGridColumn field={'previous_week_pageViews'} headerName={'Glance Views'} width={150}/>
          <AgGridColumn field={'previous_week_conversion'} headerName={'Conversion Rate'} width={150} valueFormatter={({value}) => numberWithCommas(value, 2)}/>
          <AgGridColumn field={'previous_week_asp'} headerName={'ASP'} width={150} valueFormatter={({value}) => numberWithCommas(value, 2)}/>
        </AgGridColumn>
        <AgGridColumn headerName="Shipped Revenue">
          <AgGridColumn field={'current_week_2_revenue_trend'} headerName={'Week-2'} width={150} valueFormatter={addPercentage}/>
          <AgGridColumn field={'previous_week_revenue_trend'} headerName={'Previous Week'} width={150} valueFormatter={addPercentage}/>
        </AgGridColumn>
        <AgGridColumn headerName="Shipped Units">
          <AgGridColumn field={'current_week_2_totalUnits_trend'} headerName={'Week-2'} width={150} valueFormatter={addPercentage}/>
          <AgGridColumn field={'previous_week_totalUnits_trend'} headerName={'Previous Week'} width={150} valueFormatter={addPercentage}/>
        </AgGridColumn>
        <AgGridColumn headerName="Glance Views">
          <AgGridColumn field={'current_week_2_pageViews_trend'} headerName={'Week-2'} width={150} valueFormatter={addPercentage}/>
          <AgGridColumn field={'previous_week_pageViews_trend'} headerName={'Previous Week'} width={150} valueFormatter={addPercentage}/>
        </AgGridColumn>
        <AgGridColumn headerName="Conversion Rate">
          <AgGridColumn field={'current_week_2_conversion_trend'} headerName={'Week-2'} width={150} valueFormatter={addPercentage}/>
          <AgGridColumn field={'previous_week_conversion_trend'} headerName={'Previous Week'} width={150} valueFormatter={addPercentage}/>
        </AgGridColumn>
        <AgGridColumn headerName="ASP">
          <AgGridColumn field={'current_week_2_asp_trend'} headerName={'Week-2'} width={150} valueFormatter={addPercentage}/>
          <AgGridColumn field={'previous_week_asp_trend'} headerName={'Previous Week'} width={150} valueFormatter={addPercentage}/>
        </AgGridColumn>
        <AgGridColumn headerName="Inventory">
          {/* <AgGridColumn field={'Inventory In-network'} headerName={'In-network'} width={150}/>
          <AgGridColumn field={'Inventory Dropship'} headerName={'Dropship'} width={150}/> */}
          <AgGridColumn field={'inventory'} headerName={'Total'} width={150}/>
        </AgGridColumn>
        <AgGridColumn headerName="Root Cause">
          <AgGridColumn field={'revenue_health'} headerName={'Shipped Revenue'} width={350}/>
          <AgGridColumn field={'totalUnits_health'} headerName={'Units'} width={350}/>
          <AgGridColumn field={'pageViews_health'} headerName={'Page Views'} width={350}/>
          <AgGridColumn field={'conversion_health'} headerName={'Conversions'} width={350}/>
          <AgGridColumn field={'asp_health'} headerName={'Buy Box'} width={350}/>
          <AgGridColumn field={'inventory_health'} headerName={'Inventory'} width={350}/>
        </AgGridColumn>
      </AgGridReact>
    </div>
  );
})

const addPercentage = (params) => params.value ? `${(parseFloat(params.value)).toFixed(2)}%` : "-";