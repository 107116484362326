import React from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Button } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import { getFaviconUrl } from "../../../../healpers/utilityFunctions";

export default function Table(props) {
  const ImageRenderer = (param) => {
    return (
      <img
        src={getFaviconUrl(param.data?.asin)}
        alt=""
        style={{
          height: "40px",
          width: "40px",
        }}
      />
    );
  };

  const ActionButtonsRenderer = (param) => {
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => props.editHandler(param.data)}
        >
          Edit
        </Button>
        &nbsp;
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => props.deleteHandler(param.data)}
        >
          Delete
        </Button>
      </>
    );
  };

  return (
    <>
      <div className="ag-theme-material" style={{ height: 620, width: "100%" }}>
        <AgGridReact
          defaultColDef={{
            resizable: true,
            sortable: true,
            filter: "agTextColumnFilter",
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          }}
          rowData={props.rows || []}
          headerHeight={50}
          pagination={true}
          paginationPageSize={10}
          frameworkComponents={{
            imageRenderer: ImageRenderer,
            actionButtonsRenderer: ActionButtonsRenderer,
          }}
        >
          <AgGridColumn
            colId="Image"
            headerName="Image"
            filter={false}
            sort={false}
            width={90}
            cellRenderer="imageRenderer"
          />
          <AgGridColumn headerName="ASIN" field="asin" width={150} />
          <AgGridColumn
            headerName="Title"
            field="title"
            width={350}
            cellStyle={{
              lineHeight: "normal",
              whiteSpace: "break-spaces",
            }}
          />
          <AgGridColumn headerName="Status" field="status" width={250} />
          <AgGridColumn
            headerName="Internal Feedback"
            field="internalFeedback"
            valueFormatter={(param) =>
              param.value?.filter(
                (item) =>
                  !_.startsWith(item.message, "(System generated message)")
              )?.[0]?.message
            }
            width={250}
            cellStyle={{
              lineHeight: "normal",
              whiteSpace: "break-spaces",
            }}
          />
          <AgGridColumn
            headerName="Internally Approved"
            field="internallyApproved"
            width={150}
          />
          <AgGridColumn
            headerName="Updated At"
            field="updatedAt"
            valueFormatter={(param) => moment(param.value).format("MM-DD-YYYY")}
            filter={false}
            width={150}
          />
          <AgGridColumn
            filter={false}
            sort={false}
            width={200}
            cellRenderer="actionButtonsRenderer"
          />
        </AgGridReact>
      </div>
    </>
  );
}
