/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
import ReviewBox from "./components/reviewBox/reviewBox";
//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { fetchProductAndReviewsAll } from "../../http/api";

import useStyles from "./styles";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export default function Charts(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [totalData, setTotalData] = useState([]);
  const [reviewBoxData, setReviewBoxData] = useState({});
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setfullPageLoader(true);
        if(vendorState?.selected) {
          let productAndReviewData = null, data = [];
          [productAndReviewData, data ] = await fetchProductAndReviewsAll(vendorState?.selected)
        setmainTableData(data);
        setTotalData(data);
        setReviewBoxData(data[0])
		console.error('inside fetchData method');		
        }
      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }
    
    fetchData();
/*
    return () => {
      setmainTableData([]);
    };
*/

  }, [vendorState?.selected]);

  const updateData = (id,offset) => {
    const index = totalData.findIndex((ele, i) => ele.id === id)
    setReviewBoxData(totalData[index + offset])
  }

  const goBack = () => {
    props.history.goBack()
  }
  
  const getSubHeaderLinkClass = () => {
    if(props?.match?.params?.asin) {
      return classes.subHeaderlinkActive;
    } else {
      return classes.subHeaderlink;
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="All Ratings and Reviews"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                Sales

              </Link>
              <Typography className={getSubHeaderLinkClass()} st onClick={goBack}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Products and Reviews
              </Typography>
              {props?.match?.params?.asin &&
                <Typography className={classes.link}>
                  {props?.match?.params?.asin}
                </Typography>
              }
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef}
                history={props.history}
                reRoute={!!props?.match?.params?.asin}
                tableData={mainTableData} setReviewBoxData={setReviewBoxData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
