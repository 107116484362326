import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
import { getFaviconUrl } from "../../../../healpers/utilityFunctions";

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [renderedNodes, setRenderedNodes] = useState(0);

  useEffect(() => {
    setBottomData();
  }, [renderedNodes]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleTrend = (params, key) => {
    if(params?.data[key]) {
      if(params.node.rowPinned === "bottom") {
        return params?.data[key].toFixed(2)
      } else {
        return `${(params?.data[key])?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (${params?.data[key + "Trend"]}%)`;
      }
    }
  }

  const handleTrendColor = ({ data }, key) => {
    if (data[key + "Trend"] > 0) {
      return { color: "green" };
    } else if(data[key + "Trend"] < 0) {
      return { color: "red" };
    } else {
      return { color: "black" };
    }
  }
  const rowStyle = { background: 'white' };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const FavIconRenderer = (iconProps) => {
    return (
      iconProps.data.asin
      ? <img width={50} src={getFaviconUrl(iconProps.data.asin)} alt={iconProps.data.asin}/>
      : <></>
    );
  };

  const getAverage = (array) => {
    const initialValue = 0;
    const total = array.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
    if(array.length > 0) {
      return (total/array.length).toFixed(2)
    } else {
      return 0
    }
  }

  const setBottomData = () => {
    const pinnedData = {
      avgPrice: 0,
      rating: 0,
      reviews: 0,
      sales: 0,
      revenue: 0
    }
    let reviews = []
    let ratings = []
    let avgPrice = []
    gridRef?.current?.api.getRenderedNodes().map((item) => {
      pinnedData.sales += item.data.unitSales;
      pinnedData.revenue += item.data.revenue;
      reviews.push(item.data.reviews)
      ratings.push(item.data.rating)
      avgPrice.push(item.data.avgPrice)
    })
    gridRef?.current?.api.setPinnedBottomRowData([
      {
        avgPrice: getAverage(avgPrice),
        rating: getAverage(ratings),
        reviews: getAverage(reviews),
        revenue: pinnedData.revenue,
        unitSales: pinnedData.sales,
      },
    ]);
  }

  const pinnedRowCellRenderer = (renderProps) => {
    console.log(renderProps)
  }

  const formatPrice = (params) => {
    if (typeof params.value === "number") {
      return params.value.toFixed(2)
    }
  }

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          faviconRenderer: FavIconRenderer,
          customPinnedRowRenderer : pinnedRowCellRenderer,
          
        }}
        onPaginationChanged={() => setRenderedNodes(gridRef?.current?.api?.getRenderedNodes().length)}
      >
        <AgGridColumn key={2} headerName={''} field={'favicon'} width={80} cellRenderer={"faviconRenderer"}
          cellEditorParams={{
            cellRenderer: "faviconRenderer",
          }} />
        <AgGridColumn key={2} headerName={'ASIN'} field={'asin'} width={150}/>
        <AgGridColumn key={2} headerName={'Type'} field={'type'} width={150}/>
        <AgGridColumn key={2} headerName={'Brand'} field={'brand'} width={300}/>
        <AgGridColumn key={2} headerName={'Rating'} field={'rating'} width={130}  />
        <AgGridColumn key={2} headerName={'Reviews'} field={'reviews'} width={130} />
        <AgGridColumn key={2} headerName={'Price'} field={'avgPrice'} width={130} valueFormatter={(params) => formatPrice(params)} />
        <AgGridColumn key={3} headerName={'Revenue'} valueGetter={(params) => handleTrend(params, "revenue")} width={200} cellStyle={(data) => handleTrendColor(data, "revenue")}  />
        <AgGridColumn key={6} headerName={'Unit Sales'} valueGetter={(params) => handleTrend(params, "unitSales")} width={200} cellStyle={(data) => handleTrendColor(data, "unitSales")}   />
      </AgGridReact>
    </div>
  );
})