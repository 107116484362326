import React, { useState, useRef, useEffect } from "react";
import {
	Grid,
	Breadcrumbs,
	Select,
	FormControl,
	MenuItem,
	Container,
	Button,
	CircularProgress,
	Backdrop,
	InputLabel,
	Link,
	TextField,
} from "@material-ui/core";
import axios from "axios";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

//icons
import PrintIcon from "@material-ui/icons/Print";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table/Table";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

export default function CatalogImageManager(props) {
	var classes = useStyles();
	const exportFunctionRef = useRef();
	var vendorState = useVendorState();
	const [mainTableData, setmainTableData] = useState(null);
	const [open, setOpen] = useState(false);
	const [ASIN, setASIN] = useState("");
	const [fullPageLoader, setfullPageLoader] = useState(true);
	const [file, setFile] = useState(null);
	const [imageType, setImageType] = useState("");
	const [isVCVendorCodeAvailable, setVCVendorCodeAvailable] = useState(true);
	const [vcVendorCodeAvailabilityForASIN, setVCVendorCodeAvailabilityForASIN] = useState(true);

	async function loadData() {
		try {
			setfullPageLoader(true);
			let params = {
				vendorCode: vendorState.selected
			};
			const result = await axios.get(BASE_URL + api.catalogImages, tokenConfig(params));
			console.log("result", result);
			if (result.data.data) {
				setmainTableData(result.data.data);
			}
			setfullPageLoader(false);
		} catch (error) {
			console.log(error);
			setfullPageLoader(false);
		}
	}

	async function checkVcVendorCode() {
		try {
			const params = {
				vendorCode: vendorState.selected
			}
			const result = await axios.get(BASE_URL + api.checkVcVendorCode, tokenConfig(params));
			setVCVendorCodeAvailable(result.data.data);
		} catch (error) {
			console.log("Failed to check vc vendor code", error);
		}
	}

	async function checkVCVendorCodeWithASIN() {
		if (ASIN) {
			try {
				const params = {
					asin: ASIN
				}
				const result = await axios.get(BASE_URL + api.checkVcVendorCodeWithASIN, tokenConfig(params));
				setVCVendorCodeAvailabilityForASIN(result.data.data);
			} catch (error) {
				console.log("Failed to check vc vendor code", error);
			}
		} else {
			setVCVendorCodeAvailabilityForASIN(true);
		}
	}

	useEffect(() => {
		if (vendorState.selected !== 0) {
			loadData();
			if (vendorState.selected.split("_")?.pop() === "VC") {
				checkVcVendorCode();
			} else {
				setVCVendorCodeAvailable(true);
			}
		}
		return () => {
			setmainTableData(null);
		};
	}, [vendorState.selected]);

	const handleSelectImageType = (event) => {
		setImageType(event.target.value);
	};

	const onCloseModal = () => {
		setOpen(false);
		setASIN("");
		setFile(null);
		setImageType("");
	};


	const uploadImage = (event) => {
		const file = event.target.files[0];
		event.target.value = null;
		setFile(file);
		console.log("file", file);
	}

	const handleSubmit = async () => {
		try {
			setfullPageLoader(true);
			const formData = new FormData();
			formData.append("file", file);
			formData.append("asin", ASIN);
			formData.append("imageType", imageType);
			formData.append("imageName", file.name);
			const { data: { data } } = await axios.post(BASE_URL + api.uploadCatalogImage, formData, tokenConfig());
			const errorData = data.filter(item => item.isError);
			if (errorData.length > 0) {
				let alertMessage = "Failed to upload below images: \n";
				errorData.forEach(item => {
					alertMessage += item.asin + " - " + item.imageType + " - " + item.vendorCode + " - " + item.message + "\n";
				});
				alert(alertMessage);
			} else {
				alert("Images uploaded successfully");
			}
			await loadData();
			setfullPageLoader(false);
			onCloseModal()
		} catch (error) {
			alert("Failed to upload images");
			console.log("Failed to upload", error);
			setfullPageLoader(false);
			onCloseModal()
		}
	}



	return (
		<Container maxWidth={false}>
			<PageTitle
				title="Catalog Image Manager"
				breadCrump={
					<Breadcrumbs aria-label="breadcrumb">
						<Link color="inherit" className={classes.link}>
							Catalog
						</Link>
						<Typography className={classes.link} color="primary">
							Catalog Image Manager
						</Typography>
					</Breadcrumbs>
				}
			/>
			<Grid container spacing={4} className="ams_campaign_grid">
				<Grid item xs={12} className="ams_campaign_grid_item">
					<Widget
						upperTitle
						noBodyPadding
						bodyClass={classes.tableOverflow}
						header={
							<div className={classes.mainChartHeader}>
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
									<div>
										<Typography variant="p" style={{ color: "red" }}>{isVCVendorCodeAvailable ? "" : "VC Vendor Code is not available. Please add it before uploading images."}</Typography>
									</div>
									<div>
										<Button
											variant="contained"
											color="primary"
											size="small"
											style={{ marginRight: 20 }}
											onClick={() => setOpen(true)}
										>
											Upload
										</Button>
										<Button
											variant="contained"
											color="primary"
											size="small"
											startIcon={<PrintIcon />}
											onClick={() => exportFunctionRef.current.onBtnExport()}
										>
											Export
										</Button>
									</div>
								</div>
							</div>
						}
					>
						<Table
							ref={exportFunctionRef}
							tableData={mainTableData}
							navigate={props.history.push}
						/>
					</Widget>
				</Grid>
			</Grid>
			<Backdrop className={classes.backdrop} open={fullPageLoader} onClick={() => setfullPageLoader(false)}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Modal open={open} onClose={() => onCloseModal()} styles={{ modal: { width: 600, borderRadius: 10 } }} center>
				<Backdrop className={classes.backdrop} open={fullPageLoader} onClick={() => setfullPageLoader(false)}></Backdrop>
				<Grid container style={{ paddingBottom: 30 }} alignItems="center" flexDirection="row">
					<Typography style={{ fontSize: 16, paddingRight: 10, fontWeight: 600 }}>Upload</Typography>
				</Grid>
				<Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
					<FormControl fullWidth sx={{ m: 1 }}>
						<TextField
							type="text"
							onBlur={() => checkVCVendorCodeWithASIN()}
							value={ASIN}
							id="outlined-adornment-amount"
							name="asin"
							onChange={(e) => {
								setASIN(e.target.value);
							}}
							label="ASIN"
							variant="outlined"
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="advertising-goal-label">Image Type</InputLabel>
						<Select
							labelId="advertising-goal-label"
							value={imageType}
							onChange={handleSelectImageType}
							name="imageType"
							label="Image Type"
						>
							<MenuItem value={"MAIN"}>Main</MenuItem>
							<MenuItem value={"PT01"}>PT1</MenuItem>
							<MenuItem value={"PT02"}>PT2</MenuItem>
							<MenuItem value={"PT03"}>PT3</MenuItem>
							<MenuItem value={"PT04"}>PT4</MenuItem>
							<MenuItem value={"PT05"}>PT5</MenuItem>
							<MenuItem value={"PT06"}>PT6</MenuItem>
							<MenuItem value={"PT07"}>PT7</MenuItem>
							<MenuItem value={"PT08"}>PT8</MenuItem>
							<MenuItem value={"SWATCH"}>Swatch</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} spacing={4} style={{ paddingBottom: 10 }}>
					<FormControl fullWidth sx={{ m: 1 }} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
						<label htmlFor="upload-video">
							<input
								style={{ display: "none" }}
								id="upload-video"
								name="image"
								type="file"
								accept="image/*"
								onChange={(event) => uploadImage(event)}
							/>
							<Button
								size="medium"
								color="primary"
								variant="contained"
								component="span"
								style={{
									marginBottom: 20,
									padding: "8px 30px",
								}}
							>
								Upload Image
							</Button>
						</label>
						{file && <img src={file ? URL.createObjectURL(file) : ""} alt="uploaded" style={{ width: 50, height: 50 }} />}
					</FormControl>
				</Grid>
				{!vcVendorCodeAvailabilityForASIN && <Grid item xs={12} spacing={4} style={{ paddingBottom: 10 }}>
					<Typography variant="p" style={{ color: "red", fontSize: 12 }}>VC Vendor Code is not available for this ASIN. Please add it before uploading the image.</Typography>
				</Grid>}
				<Grid container fullWidth direction="row" justifyContent="center" alignItems="center">
					<Button variant="contained" color="primary" size="small" onClick={() => handleSubmit()}>
						Submit
					</Button>
				</Grid>
			</Modal>
		</Container>
	);
}
