import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
import { getFaviconUrl } from "healpers/utilityFunctions";
// components

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const triggerRouteAction = (event) => {
    if(!props.reRoute) {
      props.history.push(`/app/bi/productAndReview/${event.data.asin}`)
    }
    props.setReviewBoxData(event.data)
  }

  const triggerBoxAction = (event) => {
    props.setReviewBoxData(event.data)
  }

  const getParentASINClass = () => {
    if(!props.reRoute) {
      return { color: 'blue', textDecoration: 'underline', cursor: 'pointer' }
    }
  }

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };

  const renderImage = ({ value }) => `<img style="height: 40px; width: 40px" src="${getFaviconUrl(value)}">`;

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          wrapText: true,
          autoHeight: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
      >
        <AgGridColumn headerName={''} field={'asin'} width={100} cellRenderer={renderImage}/>
        <AgGridColumn onCellClicked={triggerRouteAction} cellStyle={getParentASINClass()}  headerName="ASIN" field="asin" width={160} />
        <AgGridColumn headerName="Category" field="category" width={160} />
        {/* { props.reRoute && <AgGridColumn onCellClicked={triggerBoxAction} headerName="Child ASIN" field="childASIN" width={160} /> } */}
        { props.reRoute && <AgGridColumn onCellClicked={triggerBoxAction} headerName="Product Title" field="productTitle" width={600}/> }
        { props.reRoute && <AgGridColumn onCellClicked={triggerBoxAction} headerName="Review Comments" field="review" width={250}/> }
        { props.reRoute && <AgGridColumn onCellClicked={triggerBoxAction} headerName="User Name" field="userName" width={150}/> }
        { props.reRoute && <AgGridColumn onCellClicked={triggerBoxAction} headerName="Verified Purchase" field="verifiedPurchase" width={150} /> }
        { props.reRoute && <AgGridColumn onCellClicked={triggerBoxAction} headerName="Rating" field="rating" width={150} /> }
        { props.reRoute && <AgGridColumn onCellClicked={triggerBoxAction} headerName="Date" field="date" width={150} /> }
        { !props.reRoute && <AgGridColumn onCellClicked={triggerBoxAction} headerName="Product Title" field="productTitle" width={600}/> }
        { !props.reRoute && <AgGridColumn onCellClicked={triggerBoxAction} headerName="Total Reviews" field="totalReviews" width={150} /> }
        { !props.reRoute && <AgGridColumn onCellClicked={triggerBoxAction} headerName="Total Ratings" field="totalRatings" width={150} /> }
        { !props.reRoute && <AgGridColumn onCellClicked={triggerBoxAction} headerName="Overall Star Rating" field="overallStarRating" width={150} /> }

      </AgGridReact>
    </div>
  );
})