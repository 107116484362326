import React, { useState, useRef , forwardRef, useImperativeHandle } from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
import { getFaviconUrl } from "../../../../healpers/utilityFunctions";
// components

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },  
  success: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
}))

const states = {
  sent: "success",
  warning: "warning",
  secondary: "secondary",
};

export default forwardRef ( function Tables(props,ref) {
  
  const classes = useStyles();
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
        if(gridRef.current.api.getSelectedNodes().length)
            gridApi.exportDataAsCsv({onlySelected: true});
        else 
            gridApi.exportDataAsCsv();
    }
  }));

  const renderImage = ({ value }) => `<img style="height: 40px; width: 40px" src="${getFaviconUrl(value)}">`;

  const rowStyle = { background: 'white' };

  return (
    <div className="ag-theme-material" style={{height: 620, width: '100%'}} id="#grid-theme-wrapper">
        <AgGridReact
            ref={gridRef}
            rowData={props.tableData}
            suppressExcelExport={true}
            rowSelection="multiple"
            defaultColDef={{
                filter: 'agTextColumnFilter',
                resizable: true,
                sortable:true,
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +
                    '</div>',
                },              
            }}            
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={10}
            rowStyle={rowStyle}
            suppressDragLeaveHidesColumns={true}
        >
            <AgGridColumn headerName={''} field={'(Child) ASIN'} width={100} cellRenderer={renderImage}/>
            <AgGridColumn headerName="(Parent) ASIN" field="(Parent) ASIN" width={150}/>
            <AgGridColumn headerName="(Child) ASIN" field="(Child) ASIN" width={150}/>
            <AgGridColumn headerName="Title" field="Title" width={300}/>
            <AgGridColumn headerName="Sessions" field="Sessions"width={160} comparator={integerComparatorWithComma} />
            <AgGridColumn headerName="Sessions B2B" field="Sessions B2B"width={160} comparator={integerComparatorWithComma} />
            <AgGridColumn headerName="Session Percentage" field="Session Percentage" width={170} comparator={decimalComparator} />
            <AgGridColumn headerName="Session Percentage B2B" field="Session Percentage B2B" width={170} comparator={decimalComparator} />
            <AgGridColumn headerName="Page Views" field="Page Views"width={160} comparator={integerComparatorWithComma} />
            <AgGridColumn headerName="Page Views B2B" field="Page Views B2B"width={160} comparator={integerComparatorWithComma} />
            <AgGridColumn headerName="Page Views Percentage" field="Page Views Percentage" width={170} comparator={decimalComparator} />
            <AgGridColumn headerName="Page Views Percentage B2B" field="Page Views Percentage B2B" width={170} comparator={decimalComparator} />
            <AgGridColumn headerName="Featured Offer (Buy Box) Percentage" field="Featured Offer (Buy Box) Percentage"  cellStyle={cellStyle} width={170} comparator={decimalComparator} />
            <AgGridColumn headerName="Featured Offer (Buy Box) Percentage B2B" field="Featured Offer (Buy Box) Percentage B2B"  cellStyle={cellStyle} width={170} comparator={decimalComparator} />
            <AgGridColumn headerName="Units Ordered" field="Units Ordered" width={150} comparator={integerComparatorWithComma} />
            <AgGridColumn headerName="Units Ordered B2B" field="Units Ordered B2B" width={150} comparator={integerComparatorWithComma} />
            <AgGridColumn headerName="Unit Session Percentage" field="Unit Session Percentage" width={190} comparator={decimalComparator} />
            <AgGridColumn headerName="Unit Session Percentage B2B" field="Unit Session Percentage B2B" width={190} comparator={decimalComparator} />
            <AgGridColumn headerName="Ordered Product Sales" field="Ordered Product Sales" width={170} comparator={decimalComparatorWithDollar} />
            <AgGridColumn headerName="Ordered Product Sales B2B" field="Ordered Product Sales B2B" width={170} comparator={decimalComparatorWithDollar} />
            <AgGridColumn headerName="Total Order Items" field="Total Order Items" width={150} comparator={integerComparatorWithComma} />
            <AgGridColumn headerName="Total Order Items B2B" field="Total Order Items B2B" width={150} comparator={integerComparatorWithComma} />
            <AgGridColumn headerName="Start Date" field="startdate" width={150}/>
            <AgGridColumn headerName="End Date" field="enddate" width={150}/>
        </AgGridReact>
    </div>
  );
})

function cellStyle(params) {
  var color;
  if(params.value < 95)
  color = '#e6b7af'
  else color = '#d8e9d2'
  return { 
    backgroundColor: color,
    borderBottom: '1px solid rgba(178,178,178,0.25)'
  };
}

const decimalComparator = (valueA, valueB) => parseFloat(valueA) - parseFloat(valueB);

const decimalComparatorWithDollar = (valueA, valueB) => parseFloat(valueA.includes("-") ? valueA.replace("$", "") : valueA.slice(1)) - parseFloat(valueB.includes("-") ? valueB.replace("$", "") : valueB.slice(1));

const integerComparatorWithComma = (valueA, valueB) => parseInt(valueA.replace(",","")) - parseInt(valueB.replace(",",""));

