import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  FormControl,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";

//icons
import RefreshIcon from "@material-ui/icons/Refresh";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";
import { useVendorState } from "../../context/VendorContext";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function CohesityLogs() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  const userState = useUserState();
  const vendor = useVendorState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [date, setDate] = useState(new Date());

  const fetchData = async () => {
    try {
      setfullPageLoader(true);
      const response = await axios.get(BASE_URL + api.cohesityLogs, {
        params: { vendorCode: vendor.selected, date: date},
      });
      setmainTableData(response.data.data);
    } catch (error) {
      seterr(error?.response?.message);
      setTimeout(() => seterr(null), 3000);
    } finally {
      setfullPageLoader(false);
    }
  };

  useEffect(() => {
    if (userState.userData && vendor.selected) {
      fetchData();
    }
  }, [userState.userData, vendor.selected, date]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Cohesity Logs"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Catalog
              </Link>
              <Typography className={classes.link} color="primary">
                Cohesity
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
            <MuiPickersUtilsProvider utils={DateFnsUtils} fullWidth>
                <FormControl
                  variant="outlined"
                  className={classes.dateFormControl}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date"
                    label="Date"
                    value={date}
                    onChange={(date) => setDate(date)}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            </>
          }
        />
        <Grid container spacing={4} className="cohesity_logs_grid">
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    disabled={mainTableData.length === 0}
                    onClick={() => {
                      const wb = XLSX.utils.book_new();
                      const ws = XLSX.utils.json_to_sheet(mainTableData.map((item) => {
                        return {
                          ASIN: item.asin,
                          SKU: item.sku,
                          Problems: item.problems.join(", "),
                          Status: item.submissionStatus,
                        }
                      }));
                      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                      XLSX.writeFile(wb, `Cohesity_Issues_${vendor.selected}_${moment(date).format("MM-DD-YYYY")}.xlsx`);
                    }}
                  >
                    Export
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchData()}
                  >
                    Refresh
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {notification ? (
        <Alert
          severity="success"
          style={{
            position: "absolute",
            bottom: 30,
            left: "48%",
            zIndex: 9999,
          }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{
            position: "absolute",
            bottom: 30,
            left: "48%",
            zIndex: 9999,
          }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
