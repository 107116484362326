/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Backdrop,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
import ReviewBox from "./components/reviewBox/reviewBox";
//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { fetchProductAndReviews, fetchProductAndReviewsRanges } from "../../http/api";

import useStyles from "./styles";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getMonthDatesBetween, getQuarterDatesBetween, getWeekDatesBetween, getYearDatesBetween } from "healpers/utilityFunctions";
import { cloneAndSortDescending } from "healpers/dateRange.helpers";
import moment from "moment";

export default function Charts(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [dateRange, setdateRange] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [reviewBoxData, setReviewBoxData] = useState({});
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [radioValue, setradioValue] = useState("weekly");
  const [selectedDateRange, setselectedDateRange] = useState(0);  

  const [weekRange, setweekRange] = useState(null);
  const [monthRange, setmonthRange] = useState(null);
  const [quarterRange, setquarterRange] = useState(null);
  const [annualRange, setannualRange] = useState(null);
  const [range] = useState({
    startDate: moment().subtract("1", "year").toISOString(),
    endDate: moment().toISOString()
  });


  useEffect(() => {
    if (range) {
      const weekDateRange = cloneAndSortDescending([...getWeekDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const monthDateRange = cloneAndSortDescending([...getMonthDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const quarterDateRange = cloneAndSortDescending([...getQuarterDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const annualDateRange = cloneAndSortDescending([...getYearDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      setdateRange(weekDateRange);
      if (weekDateRange.length !== 0)
        setselectedDateRange(0);

      setweekRange(weekDateRange);
      setmonthRange(monthDateRange);
      setquarterRange(quarterDateRange);
      setannualRange(annualDateRange);
    }
    return () => {
      setdateRange(null);
      setweekRange([]);
      setmonthRange([]);
      setquarterRange([]);
      setannualRange([]);
    };
  }, [range]);


  useEffect(() => {
    async function fetchData() {
      try {
        if(vendorState?.selected) {
        setfullPageLoader(true);
        let productAndReviewData = null, data = [];
        if (props?.match?.params?.asin) {
          [productAndReviewData, data] = await fetchProductAndReviews(null, null, props?.match?.params?.asin)
        } else {
          [productAndReviewData, data] = await fetchProductAndReviews(vendorState?.selected, dateRange[selectedDateRange].startdate + " - " + dateRange[selectedDateRange].enddate)
        }
        setmainTableData(data);
        setTotalData(data);
        setReviewBoxData(data[0])
      }
      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if (vendorState?.selected && (selectedDateRange !== undefined || props?.match?.params?.asin)) {
      fetchData();
    }

    return () => {
      setmainTableData([]);
      setTotalData([]);
      setReviewBoxData('')
    };

  }, [selectedDateRange, vendorState?.selected, props?.match?.params?.asin]);


  const updateData = (id, offset) => {

    const index = totalData.findIndex((ele, i) => ele.id === id)
    setReviewBoxData(totalData[index + offset])
  }

  const shouldDisplay = (icon) => {
    if (props?.match?.params?.asin) {
      return classes.hideArrowIcons
    }
    const index = totalData.findIndex((ele, i) => ele.id === reviewBoxData?.id)
    if (icon === 'left') {
      if (index <= 0) {
        return classes.arrowIconDisabled;
      }
    } else {
      if (index >= totalData?.length - 1) {
        return classes.arrowIconDisabled;
      }
    }
    return classes.arrowIcon;
  }

  const goBack = () => {
    props.history.goBack()
  }

  const getSubHeaderLinkClass = () => {
    if (props?.match?.params?.asin) {
      return classes.subHeaderlinkActive;
    } else {
      return classes.subHeaderlink;
    }
  }

  const handleRadioChange = (event) => {
    setradioValue(event.target.value);
    if (event.target.value === "weekly") {
      setdateRange(weekRange);
      setselectedDateRange(0);
    } else if (event.target.value === "monthly") {
      setdateRange(monthRange);
      setselectedDateRange(0);
    } else if (event.target.value === "quarterly") {
      setdateRange(quarterRange);
      setselectedDateRange(0);
    } else {
      setdateRange(annualRange);
      setselectedDateRange(0);
    }
    // setselectedDateRange(0);
  };

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Ratings and Reviews"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                Sales
              </Link>
              <Typography className={getSubHeaderLinkClass()} st onClick={goBack}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Ratings and Reviews
              </Typography>
              {props?.match?.params?.asin &&
                <Typography className={classes.link}>
                  {props?.match?.params?.asin}
                </Typography>
              }
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl style={{ marginRight: "8%" }}>
                <RadioGroup value={radioValue} onChange={handleRadioChange}>
                  <FormControlLabel
                    value="weekly"
                    control={<Radio color="primary" />}
                    label="Weekly"
                  />
                  <FormControlLabel
                    value="monthly"
                    control={<Radio color="primary" />}
                    label="Monthly"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl style={{ marginRight: "10%" }}>
                <RadioGroup value={radioValue} onChange={handleRadioChange}>
                  <FormControlLabel
                    value="quarterly"
                    control={<Radio color="primary" />}
                    label="Quarterly"
                  />
                  <FormControlLabel
                    value="yearly"
                    control={<Radio color="primary" />}
                    label="Yearly"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Range
                </InputLabel>
                <Select
                  value={selectedDateRange}
                  onChange={(e) => {
                    setselectedDateRange(e.target.value);
                  }}
                  label="Select Range"
                  disabled={dateRange && dateRange.length === 0 ? true : false}
                >
                  {dateRange ? (
                    dateRange.map((range, index) => {
                      return (
                        <MenuItem value={index}>
                          {range.startdate + " - " + range.enddate}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Dates</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {reviewBoxData && <Widget
              bodyClass={classes.reviewBoxBody}
              headerClass={classes.reviewBoxHeader}
              header={
                <>
                  <ChevronLeftIcon onClick={() => { updateData(reviewBoxData?.id, -1) }} className={shouldDisplay('left')} />
                  <ChevronRightIcon onClick={() => { updateData(reviewBoxData?.id, 1) }} className={shouldDisplay('right')} />
                </>}
            >
              <Typography className={classes.reviewBoxMeta}>{`ASIN - ${reviewBoxData?.asin} | Title - ${reviewBoxData?.productTitle || 'NA'} | SKU - ${reviewBoxData?.sku || 'NA'}`} </Typography>
              <ReviewBox data={reviewBoxData} updateData={updateData} />
            </Widget>}
          </Grid>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef}
                history={props.history}
                reRoute={!!props?.match?.params?.asin}
                tableData={mainTableData} setReviewBoxData={setReviewBoxData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
