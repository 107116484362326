import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
import { differenceInMonths, parse } from 'date-fns';
import moment from 'moment';
import _ from 'lodash';
import { numberWithCommas, renderFavicon } from "../../../../healpers/utilityFunctions";

export default forwardRef(function Tables(props, ref) {
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    // getColumns()
    setTableData(props.tableData)
  },[props.tableData])

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const rowStyle = { background: 'white' };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const FavIconRenderer = (iconProps) => {
    return (
      <img width={50} src={`https://sd-catalog-images.s3.amazonaws.com/${iconProps.data.asin}.jpg`} alt={iconProps.data.asin} valueFormatter={removeDecimals} />
    );
  };

  const getColumns = (year) => {
    const tableKeys = props.tableKeys;
    const columns = props.columns;
  let sortedData = []
  let sortedForecastData = []
  if (!_.isEmpty(columns) && columns[year]?.length > 0) {
     sortedData = columns[year].sort((a, b) => {
      const value1 = parseInt(a.match(/^-?\d+/)[0]);
      const value2 = parseInt(b.match(/^-?\d+/)[0]);
      return value1 - value2;
    });
  }
  return sortedData
  }

  const renderColumns = (year) => {
    const array = [-1,-2,-3,-4,-5,-6,-7,-8,-9,-10,-11,-12, -13,-14,-15,-16,-17,-18,-19,-20,-21,-22,-23,-24]
    let headerName="";
    return array.map((item, index) => {
        headerName = moment().subtract(index,'month').format('MMMM')
      return <AgGridColumn headerName={headerName} field={`${array[index]}_unit_sales`} width={150}/>
    })
  }

  const renderFinalForecastColumns = () => {
    const array = [1,2,3,4,5,6,7,8,9,10,11,12]
    return array.map((item, index) => {
       const  headerName = moment().add(index+1,'month').format('MMMM')
      return <AgGridColumn 
      headerName={headerName} 
      field={`${array[index]}_${headerName}_forecast`} 
      width={160} 
      sort={index + 1 === 1 ? "desc" : ""}
      valueFormatter={({value}) => numberWithCommas(parseInt(value),0)}/>
    })
  }

  const renderWeeklyForecastColumns = () => {
    let array =[]
    for (let i = 1; i <= 48; i++) {
      array.push( <AgGridColumn
        headerName={`Week ${i}`}
        field={`week_${i}_forecast`}
        width={150}
        valueFormatter={({value}) => numberWithCommas(parseInt(value),0)}
      />)
    }
    return array
  }

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          faviconRenderer: FavIconRenderer,
        }}
      >
        <AgGridColumn>
          <AgGridColumn headerName={''} field={'asin'} width={100} cellRenderer={({value}) => renderFavicon(value)} pinned='left'/>
          <AgGridColumn headerName={'ASIN'} field={'asin'} width={150} pinned='left'/>
        </AgGridColumn>
        {/* forecast for 24 months */}
        {/* <AgGridColumn >
          {props.tableData.length > 0 && renderColumns()}
        </AgGridColumn> */}
        <AgGridColumn headerName="Final Forecast">
          {props.tableData.length >0 && renderFinalForecastColumns()}
        </AgGridColumn>
        <AgGridColumn headerName="Weekly Forecast">
          {props.tableData.length >0 && renderWeeklyForecastColumns()}
        </AgGridColumn>
      </AgGridReact>
    </div>
  );
})

const removeDecimals = (params) => {
  if(params?.value) {
    return parseFloat(params?.value).toFixed(0);
  } 
}