import React, { useState, useRef , forwardRef, useImperativeHandle } from "react";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
import { getFaviconUrl } from "../../../../healpers/utilityFunctions";

  // import moment from 'moment';
// components

export default forwardRef ( function Tables(props,ref) {
  
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const renderImage = ({ value }) => `<img style="height: 100px; width: 100px" src="${getFaviconUrl(value)}">`;

  useImperativeHandle(ref, () => ({
    onBtnExport() {
        if(gridRef.current.api.getSelectedNodes().length)
            gridApi.exportDataAsCsv({onlySelected: true});
        else 
            gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };

  return (
    <div className="ag-theme-material" style={{height: 620, width: '100%'}} id="#grid-theme-wrapper">
        <AgGridReact
            ref={gridRef}
            rowData={props.tableData}
            suppressExcelExport={true}
            rowSelection="multiple"
            defaultColDef={{
                filter: 'agTextColumnFilter',
                resizable: true,
                sortable:true,
                wrapText: true,
                autoHeight: true,
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +
                    '</div>',
                },              
            }}            
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={10}
            rowStyle={rowStyle}
            suppressDragLeaveHidesColumns={true}
        >
            <AgGridColumn headerName={''} field={'asin'} width={100} cellRenderer={renderImage}/>
            <AgGridColumn headerName="ASIN" field="asin" width={150}/>
            <AgGridColumn headerName="SKU" field="sku" width={300}/>
            <AgGridColumn headerName="Product Name" field="productName" width={300}/>
            <AgGridColumn headerName="Reason" field="reason" width={150}/>
            <AgGridColumn headerName="Issue Description" field="issueDescription" width={300}/>
            <AgGridColumn headerName="Status Change Date" field="issueDate" width={250} />
        </AgGridReact>

    </div>
  );
})
