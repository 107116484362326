import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  FormControl,
  Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";

//icons
import RefreshIcon from "@material-ui/icons/Refresh";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { tokenConfig, useUserState } from "../../context/UserContext";
import * as XLSX from "xlsx";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);

  const fetchData = async () => {
    try {
      setfullPageLoader(true);
      const response = await axios.get(BASE_URL + api.listAmsProfiles);
      setmainTableData(response.data.data);
    } catch (error) {
      console.log(error);
      seterr("Failed to fetch ams profiles")
      setTimeout(() => seterr(null), 3000)
    } finally {
      setfullPageLoader(false);
    }
  };

  useEffect(() => {
    if (userState.userData) {
      fetchData();
    }
  }, [userState.userData]);

  async function uploadPrimaryAccounts(e) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async evt => {
      try {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const fileType = file.name.split(".")[1];

        if (fileType !== "xlsx") {
          throw new Error("Invalid file type. Please upload .xlsx file");
        }
        if (data[0][0] !== "Primary Account") {
          throw new Error(`Column Primary Account is missing`);
        }
        if (data[0][1] !== "Child Accounts") {
          throw new Error(`Column Child Accounts is missing`);
        }
        let formattedData = data.map(row => {
          if (!row.length) {
            throw new Error("Don't leave any empty rows in the file");
          }
          return {
            primaryAccount: row[0].toString().trim(),
            childAccounts: row[1].toString().split(",").map(account => account.trim()),
            commission: row[2] || 0,
            commissionType: row[3] || "incremental",
          };
        });
        formattedData.shift();
        await axios.post(BASE_URL + api.changePrimaryAccounts, {
          vendors: formattedData,
        },
        tokenConfig());
        alert("Primary Accounts updated successfully");
      } catch (error) {
        console.error(error);
        alert(error.message || "Error uploading primary accounts");
      }
    };
    reader.readAsBinaryString(file);
  }

  const downloadPrimaryAccountsSample = async () => {
    try {
      const products = [{
        "Primary Account": "UNIQU_US_SC",
        "Child Accounts": "UNIQU_US_VC, UNIQI_US_SC",
        "Commission": 5,
        "Commission Type": "incremental",
      }];
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(products, { header: ["Primary Account", "Child Accounts", "Commission", "Commission Type"] });

      const fileName = `Sample Primary Accounts Sheet`;
      XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const excelBlob = new Blob([excelBuffer], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      var worksheetFileDownloadEle = document.createElement("a");
      worksheetFileDownloadEle.download = fileName + ".xlsx";
      worksheetFileDownloadEle.href = window.URL.createObjectURL(excelBlob);
      worksheetFileDownloadEle.click();
    } catch (error) {
      console.log("Variants download error", error)
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Customer Accounts"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Onboarding
              </Link>
              <Typography className={classes.link} color="primary">
                Customer Accounts
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl
                variant="outlined"
                className={classes.formControl}
              >
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={downloadPrimaryAccountsSample}
                    style={{ width: 200, marginLeft: 20, fontSize: 10 }}
                  >
                    Download Sample
                  </Button>
                  <label htmlFor="upload-csv">
                    <input
                      style={{ display: "none" }}
                      id="upload-csv"
                      name="upload-csv"
                      type="file"
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => uploadPrimaryAccounts(e)}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ width: 200, marginLeft: 20, fontSize: 10 }}
                    >
                      Upload Primary Accounts
                    </Button>
                  </label>
                </Box>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <div />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchData()}
                  >
                    Refresh
                  </Button>
                </div>
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={mainTableData}
                fetchData={fetchData}
                setnotification={setnotification}
                seterr={seterr}
                setfullPageLoader={setfullPageLoader}
              />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 30, left: "48%", zIndex: 9999 }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 30, left: "48%", zIndex: 9999 }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
