import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
import moment from "moment";
import { getFaviconUrl } from "../../../healpers/utilityFunctions";

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const rowStyle = { background: 'white' };

  const renderImage = ({ value }) => `<img style="height: 40px; width: 40px" src="${getFaviconUrl(value)}">`;

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      const ids = gridRef.current.api.getSelectedNodes().map((node) => node.data.id);
      props.exportActivities(ids);
    }
  }));

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
      >
        <AgGridColumn headerName='' width={50} headerCheckboxSelection={true}  pinned="left" checkboxSelection={true}/>
        <AgGridColumn key={1} headerName={''} field={'asin'} width={100} cellRenderer={renderImage}/>
        <AgGridColumn key={1} headerName={'ASIN'} field={'asin'} width={250}/>
        <AgGridColumn key={5} headerName={'Item Name'} field={'attributes.item_name'} valueFormatter={(params) => parseAttribute(params)} width={300} />
        <AgGridColumn key={2} headerName={'Updated Date'} field={'createdAt'} width={200} valueFormatter={dateFormat} />
        <AgGridColumn key={3} headerName={'Status'} field={'status'} width={250} />
        <AgGridColumn key={4} headerName={'Product Type'} field={'productType'} width={200} />
        <AgGridColumn key={5} headerName={'Version'} field={'version'} width={200} />
      </AgGridReact>
    </div>
  );
})

const dateFormat = (params) => params.value && moment(params.value).format("MM-DD-YYYY");
const parseAttribute = ({ value }) => value && value[0] && value[0].value;