import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";
import axios from "axios";
import moment from "moment/moment";
import { makeStyles } from "@material-ui/styles";
import marketPlaceData from "../../../../data/spMarketPlaceIds.json";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
}));

export default forwardRef(function Tables(
  { tableData, fetchData, setnotification, seterr, setfullPageLoader },
  ref
) {
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [updatedFields, setUpdatedFields] = useState({});
  const [marketPlaceIds, setMarketPlaceIds] = useState([]);
  const classes = useStyles();

  const onFormChange = (event) => {
    event.persist();
    const targetId = event.target.id ?? event.target.name;
    const value = event.target.value;
    if(event.target.type === "number") {
      setUpdatedFields((prevState) => {
        return {
          ...prevState,
          [targetId]: Number(value),
        };
      });
    } else {
      setUpdatedFields((prevState) => {
        return {
          ...prevState,
          [targetId]: value,
        };
      });
    }
    setSelectedRowData((prevState) => {
      return {
        ...prevState,
        [targetId]: value,
      };
    });
  };  

  const handleSaveChanges = async () => {
    try {
      setfullPageLoader(true)
      await axios.patch(BASE_URL + api.updateAmsProfiles, {
        id: selectedRowData.id,
        updatedFields,
      });
      setnotification("Settings saved")
      setUpdatedFields({})
      setTimeout(() => setnotification(null), 3000)
    } catch (error) {
      console.log(error);
      seterr(error.response?.data?.msg ?? "Failed to save")
      setTimeout(() => seterr(null), 3000)

    } finally {
      setfullPageLoader(false)
    }
  };
  
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  
  const handleClose = () => {
    setOpen(false);
    setSelectedRowData(null)
    fetchData()
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  React.useEffect(() => {
    getMarketplaceIDS()
  },[])
  const onEditButtonClick = (event) => {
    setSelectedRowData(event.data)
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else gridApi.exportDataAsCsv();
    },
  }));

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  const EditButtonRenderer = (e) => {
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={handleClickOpen("paper")}
      >
        EDIT
      </Button>
    );
  };

  const getMarketplaceIDS = () => {
    const marketPlaceIds = []
    marketPlaceData.map((item) => (marketPlaceIds.push({ displayValue:`${item.countryCode} - ${item.id}`, value: item.id })))
    setMarketPlaceIds(marketPlaceIds)
  }

  const fields = [
    {
      type: "TextField",
      id: "customerName",
      label: "Customer Name",
      gridSize: 6,
    },
    {
      type: "TextField",
      id: "displayName",
      label: "Display Name",
      gridSize: 6,
    },
    {
      type: "TextField",
      id: "domainName",
      label: "Domain Name",
      gridSize: 6,
    },
    {
      type: "Select",
      id: "marketplaceId",
      label: "Marketplace Id",
      gridSize: 6,
      menuItems: marketPlaceIds,
      disabled: true,
    },
    {
      type: "TextField",
      id: "scProfileId",
      label: "SC Profile Id",
      gridSize: 6,
      disabled: true,
    },
    {
      type: "TextField",
      id: "vcProfileId",
      label: "VC Profile Id",
      gridSize: 6,
      disabled: true,
    },
    {
      type: "TextField",
      id: "amsProfileId",
      label: "AMS Profile Id",
      gridSize: 12,
      disabled: true,
    },
    {
      type: "TextField",
      id: "vendorCode",
      label: "Vendor Code",
      gridSize: 6,
      disabled: true,
    },
    {
      type: "Select",
      id: "status",
      label: "Status",
      gridSize: 6,
      menuItems: [
        { displayValue: "ACTIVE", value: "active" },
        { displayValue: "INACTIVE", value: "inactive" },
      ],
      disabled: true,
    },
    {
      type: "Select",
      id: "countryCode",
      label: "Country Code",
      gridSize: 6,
      menuItems: [
        { displayValue: "US", value: "US" },
        { displayValue: "CA", value: "CA" },
        { displayValue: "MX", value: "MX" },
      ],
    },
    {
      type: "TextField",
      id: "comments",
      label: "Comments",
      gridSize: 6,
    },
    {
      type: "TextField",
      id: "primaryAccount",
      label: "Primary Account",
      gridSize: 6,
    },
    {
      type: "TextField",
      id: "commission",
      typeof: "number",
      label: "Commission",
      gridSize: 6,
    },
    {
      type: "Select",
      id: "commissionType",
      label: "Commission Type",
      gridSize: 6,
      menuItems: [
        { displayValue: "Incremental", value: "incremental" },
        { displayValue: "Overall", value: "overall" },
      ],
    },
  ];

  return (
    <div
      className="ag-theme-material"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        onCellClicked={(event) => {
          if (event.colDef.headerName === "Edit") {
            onEditButtonClick(event);
          }
        }}
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          editButtonRenderer: EditButtonRenderer,
        }}
      >
        <AgGridColumn headerName="Customer Name" field="customerName" />
        <AgGridColumn headerName="Vendor Code" field="vendorCode" />
        <AgGridColumn headerName="Primary Account" field="primaryAccount" />
        <AgGridColumn width={120} headerName="Status" field="status" />
        <AgGridColumn
          headerName="Created Date"
          field="createdDate"
          valueFormatter={(params) =>
            moment(params.value).format("DD-MM-YYYY, H:mm A")
          }
        />
        <AgGridColumn cellRenderer={"editButtonRenderer"} headerName="Edit" />
      </AgGridReact>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="md"
        >
          <DialogTitle id="scroll-dialog-title">Profile Settings</DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <form>
              <Grid container spacing={2} style={{ padding: 20 }}>
                {fields.map((field) => {
                  if (field.type === "TextField") {
                    return (
                      <Grid item xs={field.gridSize}>
                        <TextField
                          id={field.id}
                          label={field.label}
                          variant="outlined"
                          fullWidth
                          type={field.typeof}
                          disabled={field.disabled}
                          value={selectedRowData?.[field.id]}
                          onChange={onFormChange}
                        />
                      </Grid>
                    );
                  } else if (field.type === "Select") {
                    return (
                      <Grid item xs={field.gridSize}>
                        <FormControl className={classes.formControl} fullWidth variant="outlined">
                          <InputLabel id="demo-simple-select-label">
                            {field.label}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id={field.id}
                            name={field.id}
                            value={selectedRowData?.[field.id] ?? ""}
                            onChange={onFormChange}
                            disabled={field.disabled}
                          >
                            {field.menuItems.map((item) => {
                              return (
                                <MenuItem value={item.value}>
                                  {item.displayValue}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Close
            </Button>
            <Button
              onClick={handleSaveChanges}
              color="primary"
              variant="contained"
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
});
