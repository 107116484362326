import React, { useState, useRef , forwardRef, useImperativeHandle } from "react";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'

import moment from 'moment';
import { getFaviconUrl } from "../../../../healpers/utilityFunctions";
// components

export default forwardRef ( function Tables(props,ref) {
  
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
        if(gridRef.current.api.getSelectedNodes().length)
            gridApi.exportDataAsCsv({onlySelected: true});
        else 
            gridApi.exportDataAsCsv();
    }
  }));

  const renderImage = ({ value }) => `<img style="height: 100px; width: 100px" src="${getFaviconUrl(value)}">`;

  const rowStyle = { background: 'white' };

  return (
    <div className="ag-theme-material" style={{height: 620, width: '100%'}} id="#grid-theme-wrapper">
        <AgGridReact
            ref={gridRef}
            rowData={props.tableData}
            suppressExcelExport={true}
            rowSelection="multiple"
            defaultColDef={{
                filter: 'agTextColumnFilter',
                resizable: true,
                sortable:true,
                wrapText: true,
                autoHeight: true,
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +
                    '</div>',
                },              
            }}            
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={10}
            rowStyle={rowStyle}
            suppressDragLeaveHidesColumns={true}
        >
            <AgGridColumn headerName={''} field={'asin'} width={100} cellRenderer={renderImage}/>
            <AgGridColumn headerName="ASIN" field="asin" width={160} comparator={dateComparator}/>
            <AgGridColumn headerName="Title" field="title" width={380} />
            <AgGridColumn headerName="SKU" field="sku" width={200} />
            <AgGridColumn headerName="Projected Inventory Recommendation" field="pir" width={360} comparator={dateComparator}/>
            
        </AgGridReact>
    </div>
  );
})

const dateComparator = (date1, date2) => {
  if (!date1 && !date2) {
    return 0;
  } else if(!date1) {
    return -1;
  } else if (!date2) {
    return 1;
  }  
  
  let date1Number = moment(date1);
  let date2Number = moment(date2);

  if(date1Number < date2Number) {
    return -1;
  } else if(date1Number > date2Number) {
    return 1;
  } else {
    return 0;
  }
}
